<template>
  <button
    class="rounded py-1 px-3 text-white text-sm flex gap-0.5 items-center border border-white font-bold hover:border-emerald-500 hover:text-emerald-500"
    @click="handleClick"
    v-if="show"
  >
    <el-icon><Switch /></el-icon>&nbsp; Convert $ZEEP
  </button>
  <el-dialog
    v-model="visible"
    :title="$t('convertToken.title')"
    :width="320"
    append-to-body
  >
    <div>
      {{ $t('convertToken.desc') }}
    </div>
    <i18n-t
      keypath="convertToken.balance"
      tag="div"
      class="mt-4 text-slate-500"
    >
      <span class="text-white">
        {{ balance == null ? '--' : formatNumber(balance, 4) }}
      </span>
    </i18n-t>
    <template #footer>
      <el-button
        type="primary"
        class="w-full"
        v-if="toBigNumber(balance).gt(allowance)"
        :loading="balance == null"
        @click="approveToken"
      >
        {{ $t('Message.Approve') }}
      </el-button>
      <el-button
        type="primary"
        class="w-full"
        v-else
        :loading="balance == null"
        @click="convert"
        :disabled="toBigNumber(balance).isZero() || balance == null"
      >
        {{
          balance != null && toBigNumber(balance).isZero()
            ? $t('Message.exceedBalance')
            : $t('convertToken.convertAll')
        }}
      </el-button>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import { useCommonStore, useUserStore } from '@/store';
import { formatNumber, toBigNumber } from '@/utils';
import {
  allowanceTokenForAddressName,
  approveTokenForAddressName,
} from '@/web3/common';
import { convertToken } from '@/web3/v2/convert-token';
import { useDebounceFn } from '@vueuse/core';
import { Address, fetchBalance } from '@wagmi/core';
import { storeToRefs } from 'pinia';
import { bsc, bscTestnet } from 'viem/chains';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const emits = defineEmits<{
  btnClick: [];
}>();

const { t } = useI18n({ useScope: 'global' });

const { chainId, account } = storeToRefs(useUserStore());

const commonStore = useCommonStore();

const visible = ref(false);

const handleClick = () => {
  emits('btnClick');
  visible.value = true;
};

const tokenAddrMap = {
  [bsc.id]: '0x55CBAC75C1af769eB7FD37d27A5cb6437EB29abB',
  [bscTestnet.id]: '0xA346963be84a215bce16FEd8Aac0e24eca74b25E',
};

const balance = ref(null);
const allowance = ref(null);

const tokenAddress = computed(() => {
  return tokenAddrMap[chainId.value];
});

const show = computed(() => {
  return ([bsc.id, bscTestnet.id] as number[]).includes(chainId.value);
});

async function fetchTokenBalance() {
  // fetch token balance
  balance.value = null;
  try {
    const { formatted } = await fetchBalance({
      address: account.value as Address,
      token: tokenAddress.value,
      chainId: chainId.value,
    });
    balance.value = formatted;
    allowance.value = toBigNumber(
      await allowanceTokenForAddressName(
        tokenAddress.value,
        'ConvertTokenAddress'
      )
    )
      .shiftedBy(-18)
      .toString();
  } catch (error) {
    console.error(error);
    balance.value = '0';
  }
}

const debounceFetchBalance = useDebounceFn(fetchTokenBalance, 300);

watch([chainId, account, visible], () => {
  debounceFetchBalance();
});

async function approveToken() {
  commonStore.setMessageTips(true);
  commonStore.setMessageType('loading');
  try {
    await approveTokenForAddressName(tokenAddress.value, 'ConvertTokenAddress');
    await debounceFetchBalance();
    commonStore.setMessageTips(false);
    commonStore.setMessageType('success');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t('Message.Approve') + t('Message.Success'));
  } catch (e) {
    commonStore.setMessageTips(false);
    commonStore.setMessageType('error');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t(e.message));
  }
}

async function convert() {
  if (toBigNumber(balance.value).isZero()) {
    return;
  }
  commonStore.setMessageTips(true);
  commonStore.setMessageType('loading');
  try {
    await convertToken();
    await debounceFetchBalance();
    commonStore.setMessageTips(false);
    commonStore.setMessageType('success');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t('Message.Success'));
  } catch (e) {
    commonStore.setMessageTips(false);
    commonStore.setMessageType('error');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t(e.message));
  }
}
onMounted(() => {
  fetchTokenBalance();
});
</script>
