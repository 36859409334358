import { useUserStore } from '@/store';
import { ContractObject } from '@/web3/address';
import { fromWei, syncGetNetWorkName } from '@/web3/utils';
import { storeToRefs } from 'pinia';
import { Ref, computed, onMounted, ref, watch } from 'vue';
import ApplyTokenABI from '@/web3/abis/ApplyToken.json';
import { Address, readContract } from '@wagmi/core';
import { useDebounceFn } from '@vueuse/core';
import { allowanceToken } from '@/web3/common';

export function useApplyTokenPaymentCoinInfo(): [
  typeof info,
  ReturnType<typeof useDebounceFn>,
] {
  const { chainId } = storeToRefs(useUserStore());

  const info = ref<{
    zeepAddress: Address | string;
    zeepAmount: number;
    usdtAddress: Address | string;
    usdtAmount: number;
  }>({
    zeepAddress: '',
    zeepAmount: 0,
    usdtAddress: '',
    usdtAmount: 0,
  });

  const contractAddress = computed(
    () => ContractObject[syncGetNetWorkName(chainId.value)]?.ApplyTokenAddress
  );

  async function fetchData() {
    if (!contractAddress.value) {
      return;
    }

    const zeepAddress = (await readContract({
      address: contractAddress.value,
      abi: ApplyTokenABI,
      functionName: 'zeep',
      chainId: chainId.value,
    })) as Address;

    const zeepAmount = Number(
      fromWei(
        (await readContract({
          address: contractAddress.value,
          abi: ApplyTokenABI,
          functionName: 'zeepAmount',
          chainId: chainId.value,
        })) as string
      )
    );

    const usdtAddress = (await readContract({
      address: contractAddress.value,
      abi: ApplyTokenABI,
      functionName: 'usdt',
      chainId: chainId.value,
    })) as Address;

    const usdtAmount = Number(
      fromWei(
        (await readContract({
          address: contractAddress.value,
          abi: ApplyTokenABI,
          functionName: 'usdtAmount',
          chainId: chainId.value,
        })) as string
      )
    );
    info.value = {
      zeepAddress,
      zeepAmount,
      usdtAddress,
      usdtAmount,
    };
  }

  const debounceFunction = useDebounceFn(fetchData, 300);

  watch(contractAddress, () => {
    info.value = {
      zeepAddress: '',
      zeepAmount: 0,
      usdtAddress: '',
      usdtAmount: 0,
    };
    debounceFunction();
  });

  onMounted(() => {
    debounceFunction();
  });

  return [info, debounceFunction];
}

export function usePaymentCoinAllownce(
  address: Ref<Address | string>
): [typeof allowance, ReturnType<typeof useDebounceFn>] {
  const { account, chainId } = storeToRefs(useUserStore());
  const allowance = ref('0');
  const spenderAddress = computed(() => {
    return ContractObject[syncGetNetWorkName(chainId.value)]?.ApplyTokenAddress;
  });

  async function getAllowanceToken() {
    if (!address.value || !spenderAddress.value) {
      return;
    }
    try {
      allowance.value = fromWei(
        (
          await allowanceToken(
            address.value as Address,
            spenderAddress.value as Address
          )
        ).toString()
      );
    } catch (error) {
      console.error(error);
      allowance.value = '0';
    }
  }

  const debounceGetAllowanceToken = useDebounceFn(getAllowanceToken, 300);

  watch(address, () => {
    allowance.value = null;
    debounceGetAllowanceToken();
  });

  watch(account, () => {
    allowance.value = null;
    debounceGetAllowanceToken();
  });

  watch(spenderAddress, () => {
    allowance.value = null;
    debounceGetAllowanceToken();
  });

  onMounted(() => {
    debounceGetAllowanceToken();
  });

  return [allowance, debounceGetAllowanceToken];
}
