import { storeToRefs } from 'pinia';
import { ContractObject } from '../address';
import { tryCatchFunc, waitForTransaction } from '../tokens';
import { useUserStore } from '@/store';
import { syncGetNetWorkName } from '../utils';
import { writeContract } from '@wagmi/core';
import FauectABI from '@/web3/abis/Fauect.json';

export async function claimToken() {
  return await tryCatchFunc(async () => {
    const { account, chainId } = storeToRefs(useUserStore());
    const address =
      ContractObject[syncGetNetWorkName(chainId.value)]?.FauectAddress;
    if (!address || !account.value) {
      return;
    }
    const { hash } = await writeContract({
      address,
      abi: FauectABI,
      functionName: 'claim',
      chainId: chainId.value,
    });
    await waitForTransaction({ hash });
  });
}
