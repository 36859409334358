import { createWebHistory, createRouter } from 'vue-router';
import Layout from '../components/layout/index.vue';
export const routes = [
  // default Layout
  {
    path: '/',
    name: 'Home',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('../views/index/index.vue'),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: '/pool',
        name: 'Pool',
        component: () => import('../views/pool/index-v2.vue'),
        meta: {
          keepAlive: false,
        },
      },
      // {
      //   path: '/tradingCampaign',
      //   name: 'TradingCompaign',
      //   component: () => import('../views/tradingCompaign/index.vue'),
      //   meta: {
      //     keepAlive: false,
      //   },
      // },
      {
        path: '/referrals',
        name: 'Referrals',
        component: () => import('../views/referrals/index.vue'),
        meta: {
          keepAlive: false,
        },
      },
      {
        path: 'nft-v2',
        name: 'NftV2',
        component: () => import('../views/NFT/index-v2.vue'),
        meta: {
          keepAlive: false,
        },
      },
      // {
      //   path: 'nft',
      //   name: 'Nft',
      //   component: () => import('../views/NFT/index.vue'),
      //   meta: {
      //     keepAlive: false,
      //   },
      // },
      {
        path: 'bridge',
        name: 'Bridge',
        component: () => import('@/views/swap/index.vue'),
      },
      {
        path: 'swap',
        name: 'Swap',
        component: () => import('@/views/okx-swap/index.vue'),
      },
      {
        path: 'reward',
        name: 'Reward',
        component: () => import('@/views/reward/index.vue'),
      },
      {
        path: 'solana-pre',
        name: 'SolanaPre',
        component: () => import('@/views/solanaPre/index.vue'),
      },
      {
        path: 'earn',
        name: 'Staking',
        component: () => import('@/views/staking/index.vue'),
      },
      {
        path: 'rewards',
        name: 'Rewards',
        component: () => import('@/views/reward/list.vue'),
      },
      { path: '/', redirect: '/' },
    ],
  },
  // other layout
  // {
  //   path: "/index",
  //   name: "首页",
  //   component: () => import("../views/index/index.vue"),
  //   meta: {
  //     requireAuth: false,
  //     only: true,
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
