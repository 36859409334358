import { computed, onMounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useCommonStore, useUserStore, useWeb3Store } from '@/store';
import { readContract, Address } from '@wagmi/core';
import { fromWei, syncGetNetWorkName } from '@/web3/utils';
import PerpetualABI from '@/web3/abis/Perpetual.json';
import { asyncDebounce } from '@/utils';
import { ContractObject } from '@/web3/address';
import AgentABI from '@/web3/abis/AgentContract.json';
import { bsc, bscTestnet } from 'viem/chains';

/**
 * Custom hook that retrieves the minimum open position value.
 *
 * @param decimals The number of decimals to use for the minimum open position value. Default is 18.
 * @returns An object containing the minimum open position value and a refresh function.
 */
export function useMinOpenPosition(decimals = 18) {
  const { minOpenAmounts } = storeToRefs(useUserStore());

  const { symbol, currency } = storeToRefs(useCommonStore());

  const min = computed(
    () => minOpenAmounts.value[currency.value]?.[symbol.value]
  );
  return { min };
}

export function useBaseInfo() {
  const { chainId, primaryDepositMin, marginParamsList, minOpenAmounts } =
    storeToRefs(useUserStore());
  const { marketContList } = storeToRefs(useCommonStore());

  const address = computed(
    () => ContractObject[syncGetNetWorkName(chainId.value)]?.AgentAddress
  );

  async function fetchData() {
    const _address = address.value;
    const _chainId = chainId.value;
    if (!_address) {
      return;
    }

    // 初始化数据，防止闪烁
    primaryDepositMin.value = {};
    marginParamsList.value = {};
    marketContList.value = {};
    minOpenAmounts.value = {};

    const underlyingAddress =
      ContractObject[syncGetNetWorkName(_chainId)]?.UnderlyingFactoryAddr;
    const customDeploy =
      ContractObject[syncGetNetWorkName(_chainId)]?.CustomDeploy;

    const _data = (await readContract({
      address: _address,
      abi: AgentABI,
      functionName: 'getBaseInfo',
      args: [customDeploy, underlyingAddress],
    })) as any[];

    const result = _data.reduce(
      (acc, item) => {
        acc.depositMin[item.symbol] = Number(
          fromWei(item.minDespoit, item.decimals)
        );
        acc.marginParams[item.symbol] = Number(
          fromWei(item.marginRate, item.decimals)
        );

        const underlysData = item.underlys.reduce(
          (acc, underly) => {
            acc.minOpen[underly.pair] = Number(fromWei(underly.minOrderAmount));
            acc.conts[underly.pair] = Number(fromWei(underly.convert));
            return acc;
          },
          { minOpen: {}, conts: {} }
        );

        acc.minOpens[item.symbol] = underlysData.minOpen;
        acc.conts[item.symbol] = underlysData.conts;
        return acc;
      },
      { depositMin: {}, marginParams: {}, minOpens: {}, conts: {} }
    );

    primaryDepositMin.value = result.depositMin;
    marginParamsList.value = result.marginParams;
    minOpenAmounts.value = result.minOpens;
    marketContList.value = result.conts;
  }

  const deboundFetchData = asyncDebounce(fetchData, 300);

  watch(address, () => {
    deboundFetchData();
  });

  onMounted(() => {
    deboundFetchData();
  });

  return [deboundFetchData];
}

export interface IAllParams {
  name: string;
  pairInfos: Array<{
    flag: boolean;
    lot: number; // 张数转换率，精度1e18
    lpMarginRate: number; // LP保证金率，精度1e18
    maintenanceMarginRate: number; // 维持保证金率，精度1e18
    marginRate: number; // 保证金率
    maxMulti: number; // 最大倍数
    minMulti: number; // 最小倍数
    symbol: string; // 交易名称
  }>;
  settle: {
    decimals: number; // 交易精度
    exFeeRate: number; // 手续费率，精度1e18
    feeRate: number; // 手续费率，精度1e18
    flag: boolean; // 是否可交易
    settle: Address; // 结算合约地址
  };
}

export function useAllParams() {
  const { chainId, isConnect } = storeToRefs(useUserStore());
  const { marketContList, currencyOptions, allSymbolArray, currency } =
    storeToRefs(useCommonStore());
  const { primaryInfo } = storeToRefs(useWeb3Store());

  const address = computed(
    () => ContractObject[syncGetNetWorkName(chainId.value)]?.PerpetualAddress
  );

  async function fetchData() {
    const _address = address.value;
    if (!_address) {
      return;
    }

    // 初始化数据，防止闪烁
    marketContList.value = {};
    primaryInfo.value = {};

    const orgin = (await readContract({
      abi: PerpetualABI,
      functionName: 'getAllParams',
      address: _address,
    })) as IAllParams[];
    let data = orgin;
    if (([bsc.id, bscTestnet.id] as number[]).includes(chainId.value)) {
      data = orgin.reverse();
    }
    console.log(data);
    data
      .filter((item) => !!item.name)
      .forEach((item) => {
        const { settle, pairInfos, name } = item;
        const { exFeeRate, feeRate } = settle;

        marketContList.value[settle.settle] = allSymbolArray.value.reduce(
          (acc, item) => {
            acc[item] = null;
            return acc;
          },
          {}
        );

        marketContList.value[settle.settle] = pairInfos.reduce((acc, item) => {
          acc[item.symbol] = Number(fromWei(item.lot) || 0);
          return acc;
        }, {});

        marketContList.value[settle.settle] = allSymbolArray.value.reduce(
          (acc, item) => {
            const pair = marketContList.value[settle.settle][item];
            acc[item] = pair || 0;
            return acc;
          },
          {}
        );

        primaryInfo.value[settle.settle] = {
          _name: name,
          _settle: settle.settle,
          multi: pairInfos.reduce((acc, item) => {
            acc[item.symbol] = {
              max: item.maxMulti,
              min: item.minMulti,
            };
            return acc;
          }, {}),
          exFeeRate: Number(fromWei(exFeeRate, 4)),
          feeRate: Number(fromWei(feeRate, 4)),
        };
      });

    currencyOptions.value = data
      .filter((item) => !!item.name)
      .map((item) => ({
        label: item.name,
        value: item.settle.settle,
      }));
    if (
      !currencyOptions.value.map((item) => item.value).includes(currency.value)
    ) {
      currency.value = currencyOptions.value[0].value;
    }
  }

  const deboundFetchData = asyncDebounce(fetchData, 300);

  watch(address, () => {
    deboundFetchData();
  });

  watch(isConnect, (val) => {
    if (val) {
      deboundFetchData();
    }
  });

  onMounted(() => {
    deboundFetchData();
  });
}
