import useUserStore from '@/store/user';
import {
  Address,
  erc20ABI,
  readContract,
  waitForTransaction,
  writeContract,
} from '@wagmi/core';
import { tryCatchFunc } from './tokens';
import { ContractObject } from './address';
import { syncGetNetWorkName } from './utils';

/**
 * Retrieves the allowance of a token for a specific spender.
 *
 * @param address - The address of the token contract.
 * @param spender - The address of the spender.
 * @returns A Promise that resolves to the allowance value.
 */
export async function allowanceToken(address: Address, spender: Address) {
  if (!address) {
    return 0;
  }
  if (!spender) {
    return 0;
  }
  const userStore = useUserStore();
  return await readContract({
    abi: erc20ABI,
    address,
    functionName: 'allowance',
    args: [userStore.account as `0x${string}`, spender],
  });
}

export async function allowanceTokenForAddressName(
  address: Address,
  spenderName: String
) {
  return allowanceToken(
    address,
    ContractObject[syncGetNetWorkName()]?.[spenderName]
  );
}

/**
 * Approves a token for spending by a specific address.
 * @param address The address of the token contract.
 * @param spender The address of the account that will be allowed to spend the tokens.
 * @throws Throws an error if the spender address is not provided.
 */
export async function approveToken(address: Address, spender: Address) {
  await tryCatchFunc(async () => {
    const InfinityNumber =
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
    if (!spender) {
      throw new Error('');
    }
    const { hash } = await writeContract({
      abi: erc20ABI,
      address,
      functionName: 'approve',
      args: [spender, InfinityNumber],
    });
    await waitForTransaction({ hash });
  });
}

export async function approveTokenForAddressName(
  address: Address,
  spenderName: String
) {
  return approveToken(
    address,
    ContractObject[syncGetNetWorkName()]?.[spenderName]
  );
}
