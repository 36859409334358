import { defineStore } from 'pinia';
import { useWeb3Modal } from '@web3modal/wagmi/vue';
import { disconnect, getAccount, getNetwork } from '@wagmi/core';
import { getNetWorkName } from '@/web3/utils';
import {
  arbitrum,
  arbitrumSepolia,
  coreDao,
  eos,
  eosTestnet,
  polygon,
  polygonMumbai,
  bsc,
  bscTestnet,
  zkFair,
  zkFairTestnet,
  manta,
} from '@wagmi/core/chains';
import arb from '@/assets/images/header/arbitrum.svg';
import arbTest from '@/assets/images/header/arbitrum-test.svg';
import eosImg from '@/assets/images/header/eos.svg';
import eosTest from '@/assets/images/header/eostest.svg';
import polygonLogo from '@/assets/images/tokens/polygon.svg';
import coreLogo from '@/assets/images/tokens/Core.svg';
import BscLogo from '@/assets/images/header/bsc.svg';
import MerlinLogo from '@/assets/images/tokens/merlin.png';
import ZkFairLogo from '@/assets/images/tokens/ZKFair.svg';
import MantaLogo from '@/assets/images/tokens/Manta.svg';
import {
  coreDaoTestnet,
  merlinTestnet,
  mantaSepolia,
} from '@/web3/walletconnect';
import { merlin } from '../web3/walletconnect';

export const netWorkList = (
  [
    {
      chainId: arbitrum.id,
      name: 'Arbitrum',
      logo: arb,
      key: 'ARB',
    },
    {
      chainId: arbitrumSepolia.id,
      name: 'Arbitrum Test',
      logo: arbTest,
      key: 'ARBTEST',
    },
    {
      chainId: eos.id,
      name: eos.name,
      logo: eosImg,
      key: 'EOS',
    },
    {
      chainId: eosTestnet.id,
      name: eosTestnet.name,
      logo: eosTest,
      key: 'EOSTEST',
    },
    {
      chainId: polygon.id,
      name: polygon.name,
      logo: polygonLogo,
      key: 'POLYGON',
    },
    {
      chainId: polygonMumbai.id,
      name: polygonMumbai.name,
      logo: polygonLogo,
      key: 'POLYGONTEST',
    },
    {
      chainId: coreDao.id,
      name: coreDao.name,
      logo: coreLogo,
      key: 'CORE',
    },
    {
      chainId: coreDaoTestnet.id,
      name: coreDaoTestnet.name,
      logo: coreLogo,
      key: 'CORETEST',
    },
    {
      chainId: bsc.id,
      name: bsc.name,
      logo: BscLogo,
      key: 'BSC',
    },
    {
      chainId: bscTestnet.id,
      name: bscTestnet.name,
      logo: BscLogo,
      key: 'BSCTEST',
    },
    {
      chainId: merlin.id,
      name: merlin.name,
      logo: MerlinLogo,
      key: 'MERLIN',
    },
    {
      chainId: merlinTestnet.id,
      name: merlinTestnet.name,
      logo: MerlinLogo,
      key: 'MERLINTEST',
    },
    {
      chainId: zkFair.id,
      name: zkFair.name,
      logo: ZkFairLogo,
      key: 'ZKFAIR',
    },
    {
      chainId: zkFairTestnet.id,
      name: zkFairTestnet.name,
      logo: ZkFairLogo,
      key: 'ZKFAIRTEST',
    },
    {
      chainId: manta.id,
      name: manta.name,
      logo: MantaLogo,
      key: 'MANTA',
    },
    {
      chainId: mantaSepolia.id,
      name: mantaSepolia.name,
      logo: MantaLogo,
      key: 'MANTATEST',
    },
  ] as Array<{
    chainId: number;
    name: string;
    logo: string;
    key: string;
  }>
).filter((item) => {
  if (
    [
      'app.zeepr.io',
      'v1.zeepr.io',
      'm.zeepr.io',
      'mv1.zeepr.io',
      'v1m.zeepr.io',
    ].includes(location.hostname)
  ) {
    return !(
      [
        arbitrumSepolia.id,
        eosTestnet.id,
        coreDaoTestnet.id,
        polygonMumbai.id,
        bscTestnet.id,
        merlinTestnet.id,
        zkFairTestnet.id,
        mantaSepolia.id,
      ] as number[]
    ).includes(item.chainId);
  }
  return true;
});
const useUserStore = defineStore('userStore', {
  state: () => ({
    account: '',
    isConnect: false,
    chainId: arbitrum.id,
    network: 'ARB',
    marginInfo: {
      marginRatio: 0, //保证金率
      marginMaintenance: 0, //维持保证金
      marginTotal: 0, //总保证金
      marginParam: 0, //保证金系数
      totalEquity: 0, //账户总权益
      marginPNL: 0, //总盈亏
    },
    marginPNLOnj: {},
    marginParamsList: {},
    marginRatioList: {},
    userAccounts: {},
    privateAccounts: [],
    publicAccounts: [],
    primaryDepositMin: {},
    minOpenAmounts: {},
  }),
  getters: {},
  actions: {
    setAccount(account: string) {
      this.account = account;
    },
    setIsConnect(isConnect: boolean) {
      this.isConnect = isConnect;
    },
    setChainId(chainId) {
      this.chainId = chainId;
    },
    setNetWork(network) {
      this.network = network;
    },
    setMarginInfo(marginInfo) {
      this.marginInfo = marginInfo;
    },
    setMarginPNLOnj(marginPNLOnj) {
      this.marginPNLOnj = marginPNLOnj;
    },
    setMarketParamsList(marginParamsList) {
      this.marginParamsList = marginParamsList;
    },
    setMarginRatioList(marginRatioList) {
      this.marginRatioList = marginRatioList;
    },
    setUserAccounts(userAccounts) {
      this.userAccounts = userAccounts;
    },
    setPrivateAccounts(privateAccounts) {
      this.privateAccounts = privateAccounts;
    },
    setPublicAccounts(publicAccounts) {
      this.publicAccounts = publicAccounts;
    },
    setChain() {
      const isConnect = this.isConnect;
      if (!isConnect) {
        return;
      }
      const { chain } = getNetwork();
      if (
        chain &&
        netWorkList.filter((network) => network.chainId === chain.id).length > 0
      ) {
        this.setChainId(chain.id);
        getNetWorkName(chain.id).then((network) => {
          this.setNetWork(network);
        });
      }
    },
    setAccountAndChain() {
      const { address, status } = getAccount();
      if (!this.isConnect && status !== 'connected') {
        return;
      }
      this.setAccount(address);
      this.setIsConnect(status === 'connected');
    },
    login() {
      const { address } = getAccount();
      if (!address) {
        const modal = useWeb3Modal();
        modal.open();
      } else {
        this.setAccountAndChain();
      }
    },
    logout() {
      disconnect();
      this.setAccount('');
      this.setIsConnect(false);
    },
  },
});
export default useUserStore;
