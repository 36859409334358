import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from './router/index';
import ElementPlus from 'element-plus';
import {
  Popup,
  PickerGroup,
  DatePicker,
  Switch,
  Tabs,
  Tab,
  ActionSheet,
  CountDown,
} from 'vant';
import i18n from './locales';
import App from './App.vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import './assets/style/global.less';
import './assets/style/config.less';
import '@/assets/style/tailwindcss.less';
import 'element-plus/dist/index.css';
import 'swiper/dist/css/swiper.css';
import 'vant/lib/index.css';
import './assets/style/variables.less';

const app = createApp(App);
app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(ElementPlus);
app
  .use(Popup)
  .use(PickerGroup)
  .use(DatePicker)
  .use(Switch)
  .use(Tabs)
  .use(Tab)
  .use(ActionSheet)
  .use(CountDown);
app.mount('#app');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// var vConsole = new VConsole();
