<script lang="ts" setup>
import Message from "./components/common/message/index.vue";
import { onMounted, watch } from "vue";
import useCommonStore from "./store/common";
import { storeToRefs } from "pinia";
// var vConsole = new VConsole();
const commonStore = useCommonStore();
</script>
<template>
  <router-view />
  <Message />
</template>
