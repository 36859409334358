import { defineStore } from "pinia";
const useWeb3Store = defineStore("web3Store", {
  state: () => ({
    primaryKey: [],
    primaryInfo: {
      // _agent  
      // _followOrder
      // _multi 转化比
      // _name 保证金币种
      // _orderBook
      // _primary  //主合约
      // _private
      // _settle 保证金币种合约
      // _station

    }
  }),
  getters: {},
  actions: {
    setPrimaryKey(primaryKey: any) {
      this.primaryKey = primaryKey;
    },
    setPrimaryInfo(primaryInfo: any) {
      this.primaryInfo = primaryInfo;
    },
  },
});
export default useWeb3Store;
