<template>
  <el-dialog v-model="visible" :title="$t('Header.applyToken')" width="90%">
    <div class="space-y-5">
      <div class="space-y-3">
        <div class="text-sm text-white">{{ $t('applyToken.network') }}</div>
        <button
          class="flex items-center justify-between w-full text-white py-px px-[11px] bg-neutral-800 rounded h-10 active:ring-1 transition ring-emerald-500"
          @click="networkVisible = true"
        >
          <div>{{ currentNetworkName }}</div>
          <el-icon><ArrowDownBold class="text-slate-500" /></el-icon>
        </button>
      </div>

      <div class="space-y-3">
        <div class="text-sm text-white">
          {{ $t('applyToken.contractAddress') }}
        </div>
        <el-input
          v-model="contractAddress"
          :placeholder="$t('applyToken.contractAddressPlaceholder')"
        />
      </div>

      <div class="space-y-3">
        <div class="text-sm text-white">
          {{ $t('applyToken.tokenName') }}
        </div>
        <el-input
          v-model="tokenName"
          maxlength="32"
          :placeholder="$t('applyToken.tokenNamePlaceholder')"
        />
      </div>

      <div class="space-y-3">
        <div class="text-sm text-white">{{ $t('applyToken.logo') }}</div>
        <label
          class="el-button el-button--primary relative"
          :class="{
            'opacity-60 pointer-events-none': uploading,
          }"
          v-if="!logo"
          ><el-icon class="is-loading" v-if="uploading"><Loading /></el-icon
          ><span>{{
            logo ? $t('applyToken.restUpload') : $t('applyToken.uploadLogo')
          }}</span
          ><input
            type="file"
            class="absolute inset-0 opacity-0"
            @change="handleOnChangeLogo"
            accept="image/*"
          />
        </label>
        <label
          v-else
          class="relative group size-16 block rounded overflow-hidden"
        >
          <img :src="logo" class="size-full rounded obejct-cover" />
          <div
            class="group-hover:opacity-100 opacity-0 text-white absolute inset-0 pointer-events-none bg-black/60 text-xs flex items-center justify-center text-center"
            :class="{
              'opacity-100': uploading,
            }"
          >
            <el-icon class="is-loading" v-if="uploading"><Loading /></el-icon>
            <span v-else>
              {{ t('applyToken.restUpload') }}
            </span>
          </div>
          <input
            type="file"
            class="absolute inset-0 opacity-0"
            @change="handleOnChangeLogo"
            accept="image/*"
          />
        </label>
        <div class="text-xs text-slate-500">
          {{ $t('applyToken.uploadLogoLimit') }}
        </div>
      </div>

      <div class="space-y-3">
        <div class="text-sm test-white">{{ $t('applyToken.payToken') }}</div>
        <div class="flex gap-5 text-xs">
          <label
            class="relative bg-neutral-800 rounded py-3 px-5 cursor-pointer flex-1 text-center flex items-center justify-center"
          >
            <input
              type="radio"
              v-model="payMethod"
              value="zeep"
              name="paymethod"
              class="absolute inset-0 opacity-0 peer"
            />
            {{ $t('applyToken.zeepStake', [$n(info.zeepAmount, 'abbr')]) }}
            <div
              class="absolute inset-0 rounded border border-neutral-800 hover:border-white transition peer-checked:border-emerald-500 overflow-hidden grouppeer"
            >
              <div
                class="bg-emerald-500 rotate-45 right-0 top-0 size-8 translate-x-1/2 absolute -translate-y-1/2 opacity-0 transition peer-checked:group-[peer]:opacity-100"
              ></div>
              <div
                class="absolute right-0 top-0 opacity-0 transition peer-checked:group-[peer]:opacity-100"
              >
                <el-icon><Check class="text-white" /></el-icon>
              </div>
            </div>
          </label>
          <label
            class="relative bg-neutral-800 rounded py-3 px-5 cursor-pointer flex-1 text-center flex items-center justify-center"
          >
            <input
              type="radio"
              v-model="payMethod"
              value="usdt"
              name="paymethod"
              class="absolute inset-0 opacity-0 peer"
            />
            {{ $t('applyToken.payUsdt', [info.usdtAmount]) }}

            <div
              class="absolute inset-0 rounded border border-neutral-800 hover:border-white transition peer-checked:border-emerald-500 overflow-hidden grouppeer"
            >
              <div
                class="bg-emerald-500 rotate-45 right-0 top-0 size-8 translate-x-1/2 absolute -translate-y-1/2 opacity-0 transition peer-checked:group-[peer]:opacity-100"
              ></div>
              <div
                class="absolute right-0 top-0 opacity-0 transition peer-checked:group-[peer]:opacity-100"
              >
                <el-icon><Check class="text-white" /></el-icon>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="text-sm text-slate-500">{{ $t('applyToken.tips') }}</div>
    </div>
    <template #footer>
      <el-button
        type="primary"
        v-if="!isConnect"
        class="w-full"
        @click="userStore.login"
        >{{ $t('Header.ConnectWallet') }}
      </el-button>
      <el-button
        type="primary"
        v-else-if="![bsc.id, bscTestnet.id].includes(chainId as any)"
        @click="switchBSC"
        class="w-full"
        >{{ $t('applyToken.switchBSC') }}</el-button
      >
      <el-button
        type="primary"
        v-else-if="!hasAllownce"
        class="w-full"
        @click="approveAmount"
      >
        {{ $t('Trade.Approve') }}
      </el-button>
      <el-button
        type="primary"
        v-else
        class="w-full"
        :loading="uploading || usdtAllownce === null || zeepAddress === null"
        @click="apply"
        >{{ $t('applyToken.confirm') }}</el-button
      >
    </template>
  </el-dialog>
  <van-popup
    v-model:show="networkVisible"
    close-icon="cross"
    closeable
    position="bottom"
    :z-index="9999"
    :style="{
      width: '100%',
      maxHeight: '80%',
      minHeight: '50%',
      background: '#101010',
    }"
    class="symbol_dialog"
  >
    <div class="symbol_dialog_close">
      <span>{{ $t('applyToken.network') }}</span>
    </div>
    <div class="min-h-min flex-1 space-y-1 overflow-y-auto px-3 pb-3">
      <div v-for="d in netWorkList" :key="d.chainId">
        <div
          class="text-sm py-2 flex items-center hover:text-emerald-500 gap-2 transition"
          :class="{
            'text-slate-500': d.chainId !== network,
            'text-emerald-500': d.chainId === network,
          }"
          @click="
            network = d.chainId;
            networkVisible = false;
          "
        >
          <img :src="d.logo" class="size-6 object-cover flex-shrink-0" />
          <div>
            {{ d.name }}
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import useUserStore, { netWorkList } from '@/store/user';
import { storeToRefs } from 'pinia';
import { awsUploadFile } from '@/utils/aws-uploader';
import { useI18n } from 'vue-i18n';
import { ElNotification } from 'element-plus';
import Toast from '@/components/common/toast';
import {
  useApplyTokenPaymentCoinInfo,
  usePaymentCoinAllownce,
} from '@/hooks/v2/apply-token';
import { useCommonStore } from '@/store';
import { addToken } from '@/web3/v2/apply-token';
import { Address } from 'viem';
import { toBigNumber } from '@/utils';
import { ContractObject } from '@/web3/address';
import { syncGetNetWorkName } from '@/web3/utils';
import { approveToken } from '@/web3/common';
import { bsc, bscTestnet } from 'viem/chains';
import { switchNetwork } from '@wagmi/core';

const props = defineProps<{
  modelValue: boolean;
}>();

const emits = defineEmits<{
  'update:modelValue': [boolean];
}>();

const { t } = useI18n({
  useScope: 'global',
});

const userStore = useUserStore();
const { chainId, isConnect } = storeToRefs(userStore);

const commonStore = useCommonStore();

const [info] = useApplyTokenPaymentCoinInfo();
const zeepAddress = ref(info.value.zeepAddress);
const usdtAddress = ref(info.value.usdtAddress);
watch(info, (val) => {
  zeepAddress.value = val.zeepAddress;
  usdtAddress.value = val.usdtAddress;
});

const [zeepAllownce, zeepRefreshAllowance] =
  usePaymentCoinAllownce(zeepAddress);
const [usdtAllownce, usdtRefreshAllowance] =
  usePaymentCoinAllownce(usdtAddress);

const hasAllownce = computed(() => {
  if (zeepAllownce.value === null || usdtAllownce.value === null) {
    return true;
  }
  if (payMethod.value === 'zeep') {
    return toBigNumber(zeepAllownce.value).gte(info.value.zeepAmount);
  }
  if (payMethod.value === 'usdt') {
    return toBigNumber(usdtAllownce.value).gte(info.value.usdtAmount);
  }
  return false;
});
const visible = ref(props.modelValue);

watch(
  () => props.modelValue,
  (val) => {
    visible.value = val;
  }
);

watch(visible, (val) => {
  emits('update:modelValue', val);
});

const networkVisible = ref(false);

const network = ref<number>(chainId.value);
const currentNetworkName = computed(
  () => netWorkList.find((item) => item.chainId === network.value)?.name
);
const contractAddress = ref('');
const tokenName = ref('');
const logo = ref('');
const uploading = ref(false);
const payMethod = ref<'zeep' | 'usdt'>('zeep');

async function handleOnChangeLogo({ target }: Event) {
  const file = (target as HTMLInputElement).files[0];
  if (file.size > 1024 * 100) {
    ElNotification(
      Toast({
        type: 'error',
        message: t('applyToken.uploadLogoLimit'),
      })
    );
    return;
  }
  uploading.value = true;
  try {
    const [{ url }] = await awsUploadFile(file, 'images');
    logo.value = url;
    (target as HTMLInputElement).value = null;
  } catch (e) {
    console.log(e);
  } finally {
    uploading.value = false;
  }
}

async function switchBSC() {
  switchNetwork({
    chainId: location.hostname === 'app.zeepr.io' ? bsc.id : bscTestnet.id,
  });
}

async function approveAmount() {
  commonStore.setMessageTips(true);
  commonStore.setMessageType('loading');
  try {
    if (payMethod.value === 'zeep') {
      await approveToken(
        info.value.zeepAddress as Address,
        ContractObject[syncGetNetWorkName()].ApplyTokenAddress
      );
      await zeepRefreshAllowance();
    } else {
      await approveToken(
        info.value.usdtAddress as Address,
        ContractObject[syncGetNetWorkName()].ApplyTokenAddress
      );
      await usdtRefreshAllowance();
    }
    commonStore.setMessageTips(false);
    commonStore.setMessageType('success');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t('Message.Success'));
  } catch (e) {
    commonStore.setMessageTips(false);
    commonStore.setMessageType('error');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t(e.message));
    throw e;
  }
}

async function apply() {
  if (!network.value) {
    ElNotification(
      Toast({
        type: 'error',
        message: t('applyToken.networkRequired'),
      })
    );
    return;
  }
  if (!contractAddress.value) {
    ElNotification(
      Toast({
        type: 'error',
        message: t('applyToken.contractAddressPlaceholder'),
      })
    );
    return;
  }
  if (!tokenName.value) {
    ElNotification(
      Toast({
        type: 'error',
        message: t('applyToken.tokenNamePlaceholder'),
      })
    );
    return;
  }
  if (tokenName.value.length > 32) {
    ElNotification(
      Toast({
        type: 'error',
        message: t('applyToken.tokenNameBigLength'),
      })
    );
    return;
  }
  if (!logo.value) {
    ElNotification(
      Toast({
        type: 'error',
        message: t('applyToken.uploadLogoReqired'),
      })
    );
    return;
  }

  try {
    commonStore.setMessageTips(true);
    commonStore.setMessageType('loading');
    await addToken(
      contractAddress.value,
      tokenName.value,
      logo.value,
      payMethod.value === 'zeep'
        ? (info.value.zeepAddress as Address)
        : (info.value.usdtAddress as Address),
      network.value
    );
    zeepRefreshAllowance();
    usdtRefreshAllowance();

    commonStore.setMessageTips(false);
    commonStore.setMessageType('success');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t('Message.Success'));
  } catch (e) {
    commonStore.setMessageTips(false);
    commonStore.setMessageType('error');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t(e.message));
    throw e;
  }
}
</script>
