import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';

const numberFormats = {
  'en-US': {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    basic: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    crypto: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 22,
    },
    abbr: {
      style: 'decimal',
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
  },
  'zh-TW': {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    basic: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    crypto: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 22,
    },
    abbr: {
      style: 'decimal',
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
  },
  'tr-TR': {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    basic: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    crypto: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 22,
    },
    abbr: {
      style: 'decimal',
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
  },
  'ko-KR': {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    basic: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    crypto: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 22,
    },
    abbr: {
      style: 'decimal',
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
  },
  'vi-VN': {
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    basic: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    crypto: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 22,
    },
    abbr: {
      style: 'decimal',
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: 0,
      maximumFractionDigits: 4,
    },
  },
};
const i18n = createI18n({
  locale: localStorage.getItem('locale')?.replace('_', '-') || 'en-US',
  messages,
  warnHtmlMessage: false,
  numberFormats,
  pluralRules: {
    'en-US': (choice, choiceLength) => {
      return Math.min(choice, 3, choiceLength - 1);
    },
    'zh-CN': (choice, choiceLength) => {
      return Math.min(choice, 3, choiceLength - 1);
    },
    'zh-HK': (choice, choiceLength) => {
      return Math.min(choice, 3, choiceLength - 1);
    },
    'zh-TW': (choice, choiceLength) => {
      return Math.min(choice, 3, choiceLength - 1);
    },
    'tr-TR': (choice, choiceLength) => {
      return Math.min(choice, 3, choiceLength - 1);
    },
    'ko-KR': (choice, choiceLength) => {
      return Math.min(choice, 3, choiceLength - 1);
    },
    'vi-VN': (choice, choiceLength) => {
      return Math.min(choice, 3, choiceLength - 1);
    },
  },
});
export default i18n;
