import web3 from 'web3';
import BigNumber from 'bignumber.js';
import { NetworkFilter } from './address';
import '@/web3/walletconnect';
import {
  getAccount,
  getNetwork,
  getPublicClient,
  WaitForTransactionArgs,
  WaitForTransactionResult,
  watchAccount,
} from '@wagmi/core';
import { CallParameters, hexToString } from 'viem';
import { tryCatchFunc as tokenTryCatchFunc } from './tokens';

BigNumber.config({ EXPONENTIAL_AT: [-20, 40] });

export const Web3 = new web3();

watchAccount(async (account) => {
  if (!!account.connector) {
    if (!Web3.currentProvider) {
      Web3.setProvider(await account.connector.getProvider());
    }
  }
});

// 实例化合约
export const getContract = async (abi: any, address: string) => {
  if (!Web3.currentProvider && getAccount().status === 'connected') {
    Web3.setProvider(await getAccount().connector.getProvider());
  }
  Web3.eth.handleRevert = true;
  return new Web3.eth.Contract(abi, address);
};

export const toWei = (
  value: string | number,
  decimals: string | number = 18
) => {
  return new BigNumber(value)
    .multipliedBy(new BigNumber(10).pow(decimals))
    .toString();
};

export const fromWei = (
  value: string | number,
  decimals: string | number = 18
) => {
  if (!decimals) {
    return value.toString();
  }
  return new BigNumber(value)
    .dividedBy(new BigNumber(10).pow(decimals))
    .toString();
};

export const formatAddress = (address: string) => {
  if (!address) return '';
  return address.slice(0, 6) + '...' + address.slice(-4);
};
export const formatHiddenAddress = (address: string) => {
  return address.slice(0, 6) + '***' + address.slice(-6);
};
export const getNetWorkName = async (id = null) => {
  let chainId = id;
  if (!chainId) {
    chainId = getNetwork().chain?.id;
  }
  const network = NetworkFilter(Number(chainId));
  return network || 'ARB';
};
export const syncGetNetWorkName = (id = null) => {
  let chainId = id;
  if (!chainId) {
    chainId = getNetwork().chain?.id;
  }
  const network = NetworkFilter(Number(chainId));
  return network || 'ARB';
};
export function parseJson(string: string) {
  const jsonStringStack = [];
  const jsonObjects = [];

  let currentJsonString = '';
  let count = 0;

  for (let i = 0; i < string.length; i++) {
    if (string[i] === '{') {
      if (count === 0) {
        jsonStringStack.push(i);
      }
      count++;
    } else if (string[i] === '}') {
      count--;

      if (count === 0) {
        const jsonStringStartIndex = jsonStringStack.pop();
        currentJsonString = string.substring(jsonStringStartIndex, i + 1);

        try {
          const jsonObject = JSON.parse(currentJsonString);
          jsonObjects.push(jsonObject);
        } catch (error) {
          console.error('JSON解析错误:', error);
        }

        currentJsonString = '';
      }
    }
  }

  return jsonObjects;
}

export async function waitForTransaction({
  chainId,
  confirmations = 1,
  hash,
  onReplaced,
  timeout = 0,
}: WaitForTransactionArgs): Promise<WaitForTransactionResult> {
  const publicClient = getPublicClient({ chainId });

  const receipt = await publicClient.waitForTransactionReceipt({
    hash,
    confirmations,
    onReplaced,
    timeout,
  });
  if (receipt.status === 'reverted') {
    const txn = await publicClient.getTransaction({
      hash: receipt.transactionHash,
    });
    const code = (await publicClient.call({
      ...txn,
      data: txn.input,
      gasPrice: txn.type !== 'eip1559' ? txn.gasPrice : undefined,
      maxFeePerGas: txn.type === 'eip1559' ? txn.maxFeePerGas : undefined,
      maxPriorityFeePerGas:
        txn.type === 'eip1559' ? txn.maxPriorityFeePerGas : undefined,
    } as CallParameters)) as unknown as string;
    const reason = hexToString(`0x${code.substring(138)}`);
    throw new Error(reason);
  }
  return receipt;
}
export async function tryCatchFunc(func: () => Promise<any>) {
  return await tokenTryCatchFunc(func);
}

/**
 * Retrieves the current gas price.
 *
 * @returns {Promise<string>} The gas price in Wei as a string.
 */
export async function getGasPrice() {
  return BigNumber(await Web3.eth.getGasPrice())
    .times('1.2')
    .toString();
}
