<script lang="ts" setup>
import { useCommonStore, useUserStore } from '@/store/index';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { formatAddress } from '@/web3/utils';
import ERRORNET from '@/assets/images/header/error.svg';
import i18n from '@/locales';
import {
  switchNetwork,
  watchAccount,
  watchNetwork,
  getNetwork,
} from '@wagmi/core';
import { useWeb3Store } from '@/store';
import { netWorkList } from '@/store/user';
import {
  arbitrum,
  arbitrumSepolia,
  bsc,
  bscTestnet,
  coreDao,
  eos,
  eosTestnet,
} from '@wagmi/core/chains';
import { coreDaoTestnet, merlin } from '@/web3/walletconnect';
import ApplyToken from '@/components/applyToken/index.vue';
import { useFauectTime } from '@/hooks/v2/fauect';
import { claimToken } from '@/web3/v2/fauect';
import { useI18n } from 'vue-i18n';
import ConvertToken from './convertToken.vue';

const { t } = useI18n({
  useScope: 'global',
});

const userStore = useUserStore();
const commonStore = useCommonStore();
const { account, isConnect, chainId } = storeToRefs(userStore);
const tmpChainId = ref(chainId.value);
const { languageOptions } = storeToRefs(commonStore);
const curLang = ref(
  (localStorage.getItem('locale') || commonStore.lang || 'en-US').replace(
    '_',
    '-'
  )
);
const web3Store = useWeb3Store();
const { primaryInfo } = storeToRefs(web3Store);

const maskAccount = computed(() => {
  const acc = account.value || '0x0000000000000000000000000000000000000000';
  return acc.slice(0, 18) + '...' + acc.slice(-5);
});

const networkLogo = computed(() => {
  return (
    (netWorkList.filter((item) => item.chainId == tmpChainId.value)[0] &&
      netWorkList.filter((item) => item.chainId == tmpChainId.value)[0].logo) ||
    ERRORNET
  );
});

const baseUniswapUrl =
  'https://app.uniswap.org/swap?inputCurrency=USDT&outputCurrency=';
const chainMap = {
  [arbitrum.id]: 'arbitrum',
  [arbitrumSepolia.id]: 'arbitrum',
};

const buyZEEPLink = computed(() => {
  const chain = chainMap[tmpChainId.value];
  return chain
    ? `${baseUniswapUrl}${primaryInfo.value['ZEEP']?._settle}&chain=${chain}`
    : '';
});

const swapWEOS = computed(() => {
  if (![eos.id, eosTestnet.id].includes(tmpChainId.value)) {
    return null;
  }
  return 'https://noahark.io/swap/?outputCurrency=0xc00592aA41D32D137dC480d9f6d0Df19b860104F&chainId=17777&inputCurrency=EOS';
});

const swapWCore = computed(() => {
  if (![coreDao.id, coreDaoTestnet.id].includes(tmpChainId.value)) {
    return null;
  }
  return 'https://www.sushi.com/swap?chainId=1116&token0=NATIVE&token1=0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f';
});

const sliderVisibile = ref(false);
const handleClickConnectWallet = () => {
  userStore.login();
};
const handleClickUnConnectWallet = () => {
  userStore.logout();
};
const handleClickChangeNetwork = async (data) => {
  if ([merlin.id].includes(data.chainId)) {
    return;
  }
  if (isConnect.value) {
    await switchNetwork({ chainId: data.chainId });
  } else {
    chainId.value = data.chainId;
  }
};

function redirectToURL(url) {
  window.location.href = url;
}

const handleClickSetLocale = (locale) => {
  curLang.value = locale;
  commonStore.setLanguage(locale);
  localStorage.setItem('locale', locale);
  i18n.global.locale.value = locale;
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
};

watch(chainId, (val) => {
  tmpChainId.value = val;
});
// 监听钱包变化
watchAccount(async (data) => {
  if (data.status === 'connected') {
    if (
      !netWorkList.filter(
        (network) => network.chainId === getNetwork().chain?.id
      ).length
    ) {
      await switchNetwork({
        chainId: tmpChainId.value || netWorkList[0].chainId,
      });
    }
  }
  userStore.setAccountAndChain();
});
// 监听网路变化
watchNetwork(() => {
  if (
    isConnect.value &&
    !netWorkList.filter((network) => network.chainId === getNetwork().chain?.id)
      .length
  ) {
    userStore.logout();
  } else if (isConnect.value) {
    userStore.setChain();
  }
});

const applyTokenVisible = ref(false);

const fauectVisibile = ref(false);
const [fauectTime, refreshFauect] = useFauectTime();
const now = ref(Date.now());
const time = ref(0);
const rqafId = ref(null);
const hasClaimFauect = computed(() => {
  return fauectTime.value > now.value;
});
watch(fauectTime, (val) => {
  time.value = val - Date.now();
});

function rqafTimer() {
  now.value = Date.now();
  rqafId.value = requestAnimationFrame(() => {
    rqafTimer();
  });
}

async function switchBSC() {
  if (['app.zeepr.io', 'm.zeepr.io'].includes(location.hostname)) {
    await switchNetwork({ chainId: bsc.id });
    return;
  }
  await switchNetwork({ chainId: bscTestnet.id });
}

async function handleClickClaimFauect() {
  if (hasClaimFauect.value) {
    return;
  }
  commonStore.setMessageTips(true);
  commonStore.setMessageType('loading');
  try {
    await claimToken();
    await refreshFauect();
    commonStore.setMessageTips(false);
    commonStore.setMessageType('success');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t('Message.Success'));
  } catch (e) {
    commonStore.setMessageTips(false);
    commonStore.setMessageType('error');
    commonStore.setMessageTips(true);
    commonStore.setMessageTxt(t(e.message));
    throw e;
  }
}

onMounted(() => {
  cancelAnimationFrame(rqafId.value);
  rqafTimer();
});

onUnmounted(() => {
  cancelAnimationFrame(rqafId.value);
});
</script>

<template>
  <div class="header">
    <div class="header_l">
      <span>
        <img
          src="@/assets/images/header/more.svg"
          @click="sliderVisibile = true"
        />
      </span>
      <a href="https://www.zeepr.io/">
        <img src="@/assets/images/header/logo.svg" alt="" />
      </a>
    </div>
    <div class="header_r">
      <div class="header_r_wallet">
        <el-dropdown trigger="hover" placement="bottom" v-if="isConnect">
          <div class="connected_wallet">
            <span>{{ formatAddress(account) }}</span>
            <el-icon class="arrow"><ArrowDown /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleClickUnConnectWallet">{{
                $t('Header.disconnect')
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div class="connect_wallet" @click="handleClickConnectWallet" v-else>
          <img src="@/assets/images/header/wallet.svg" alt="" />
          <span>{{ $t('Header.ConnectWallet') }}</span>
        </div>
      </div>
      <div class="header_r_network">
        <div class="header_r_network_l">
          <el-dropdown
            :teleported="false"
            trigger="click"
            :placement="'bottom-end'"
          >
            <span class="el-dropdown-link">
              <img :src="networkLogo" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in netWorkList"
                  :key="item.chainId"
                  :class="tmpChainId == item.chainId ? 'active' : ''"
                  @click="handleClickChangeNetwork(item)"
                >
                  <div
                    :class="
                      tmpChainId == item.chainId
                        ? 'network_item network_item_active'
                        : 'network_item'
                    "
                  >
                    <img :src="item.logo" />
                    <span>{{ item.name }}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="correct"
                    >
                      <path
                        d="M3 8L7 12L14 5"
                        stroke="#2354E6"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="header_r_network_c"></div>
        <div class="header_r_network_r">
          <el-dropdown
            :teleported="false"
            trigger="click"
            :placement="'bottom-end'"
          >
            <span class="el-dropdown-link">
              <img src="../../../assets/images/header/world.svg" />
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in languageOptions"
                  :key="item.value"
                  @click="handleClickSetLocale(item.value)"
                  :class="curLang == item.value ? 'active' : ''"
                >
                  <div
                    :class="
                      curLang == item.value
                        ? 'language_item language_item_active'
                        : 'language_item'
                    "
                  >
                    <img :src="item.logo" />
                    <span>{{ item.label }}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="correct"
                    >
                      <path
                        d="M3 8L7 12L14 5"
                        stroke="#2354E6"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </svg>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
  <!-- siderBar -->
  <van-popup
    v-model:show="sliderVisibile"
    position="left"
    :style="{ width: '80%', height: '100%', background: '#101010' }"
    class="slider_dialog"
  >
    <h3 class="flex gap-1 items-center justify-between">
      <a href="https://www.zeepr.io/">
        <img src="@/assets/images/header/logo.svg" alt="" class="h-7" />
      </a>
    </h3>
    <div>
      <div class="flex rounded border border-slate-500 text-center">
        <a class="flex-1 rounded bg-emerald-500 text-white">V2</a>
        <a href="https://v3m.zeepr.io" class="flex-1 text-white">V3</a>
      </div>
    </div>
    <div>
      <router-link to="/" @click="sliderVisibile = false">{{
        $t('Header.Trade')
      }}</router-link>
      <router-link to="/pool" @click="sliderVisibile = false" v-if="true">{{
        $t('Header.LiquidityPool')
      }}</router-link>
      <router-link
        :to="{ name: 'TradingCompaign' }"
        @click="sliderVisibile = false"
        v-if="false"
      >
        {{ $t('Header.TradingCompaign') }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8 14.6654C10.7449 14.6654 12.9999 12.4901 12.9999 9.69806C12.9999 9.01263 12.9651 8.27786 12.5852 7.1359C12.2052 5.99396 12.1287 5.84656 11.727 5.14133C11.5553 6.5805 10.637 7.1803 10.4037 7.35956C10.4037 7.17306 9.84817 5.11066 9.00587 3.87666C8.179 2.66536 7.05447 1.87067 6.3951 1.33203C6.3951 2.35529 6.1073 3.87666 5.69513 4.65183C5.28297 5.427 5.20557 5.45523 4.69073 6.0321C4.17593 6.60896 3.93963 6.78713 3.50913 7.48706C3.07865 8.18703 3 9.1193 3 9.80473C3 12.5968 5.2551 14.6654 8 14.6654Z"
            fill="#C2334B"
            stroke="#C2334B"
            stroke-width="1.33333"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
      <RouterLink :to="{ name: 'Reward' }" @click="sliderVisibile = false">
        Reward
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8 14.6654C10.7449 14.6654 12.9999 12.4901 12.9999 9.69806C12.9999 9.01263 12.9651 8.27786 12.5852 7.1359C12.2052 5.99396 12.1287 5.84656 11.727 5.14133C11.5553 6.5805 10.637 7.1803 10.4037 7.35956C10.4037 7.17306 9.84817 5.11066 9.00587 3.87666C8.179 2.66536 7.05447 1.87067 6.3951 1.33203C6.3951 2.35529 6.1073 3.87666 5.69513 4.65183C5.28297 5.427 5.20557 5.45523 4.69073 6.0321C4.17593 6.60896 3.93963 6.78713 3.50913 7.48706C3.07865 8.18703 3 9.1193 3 9.80473C3 12.5968 5.2551 14.6654 8 14.6654Z"
            fill="#C2334B"
            stroke="#C2334B"
            stroke-width="1.33333"
            stroke-linejoin="round"
          />
        </svg>
      </RouterLink>
      <router-link
        v-if="
          [
            arbitrum.id,
            arbitrumSepolia.id,
            eosTestnet.id,
            bsc.id,
            bscTestnet.id,
            coreDao.id,
          ].includes(chainId)
        "
        :to="{ name: 'NftV2' }"
        @click="sliderVisibile = false"
        >{{ $t('Header.nft') }}</router-link
      >
      <router-link
        :to="{ name: 'Staking' }"
        @click="sliderVisibile = false"
        v-if="true"
        >Earn
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8 14.6654C10.7449 14.6654 12.9999 12.4901 12.9999 9.69806C12.9999 9.01263 12.9651 8.27786 12.5852 7.1359C12.2052 5.99396 12.1287 5.84656 11.727 5.14133C11.5553 6.5805 10.637 7.1803 10.4037 7.35956C10.4037 7.17306 9.84817 5.11066 9.00587 3.87666C8.179 2.66536 7.05447 1.87067 6.3951 1.33203C6.3951 2.35529 6.1073 3.87666 5.69513 4.65183C5.28297 5.427 5.20557 5.45523 4.69073 6.0321C4.17593 6.60896 3.93963 6.78713 3.50913 7.48706C3.07865 8.18703 3 9.1193 3 9.80473C3 12.5968 5.2551 14.6654 8 14.6654Z"
            fill="#C2334B"
            stroke="#C2334B"
            stroke-width="1.33333"
            stroke-linejoin="round"
          />
        </svg>
      </router-link>
      <router-link
        :to="{ name: 'Swap' }"
        @click="sliderVisibile = false"
        v-if="true"
        >Swap</router-link
      >
      <router-link
        :to="{ name: 'Bridge' }"
        @click="sliderVisibile = false"
        v-if="true"
        >{{ $t('swap.bridge') }}</router-link
      >
      <a
        @click="
          sliderVisibile = false;
          applyTokenVisible = true;
        "
      >
        {{ $t('Header.applyToken') }}
      </a>
      <router-link
        to="/referrals"
        v-if="true"
        @click="sliderVisibile = false"
        >{{ $t('Header.Rebate') }}</router-link
      >
      <RouterLink
        :to="{ name: 'SolanaPre' }"
        @click="sliderVisibile = false"
        v-if="false"
        >{{ $t('Header.solanaPre') }}</RouterLink
      >
      <a
        @click="
          fauectVisibile = true;
          sliderVisibile = false;
        "
        >{{ $t('Header.Faucet') }}</a
      >
      <a href="https://zeepr.gitbook.io/docs/" target="_blank">{{
        $t('Header.doc')
      }}</a>
      <a :href="buyZEEPLink" target="_blank" class="plan-link" v-if="false">
        <el-icon><Switch /></el-icon>&nbsp; Buy ZEEP</a
      >
      <a :href="swapWEOS" target="_blank" class="plan-link" v-if="swapWEOS">
        <el-icon><Switch /></el-icon>&nbsp; Swap WEOS</a
      >
      <a :href="swapWCore" target="_blank" class="plan-link" v-if="swapWCore">
        <el-icon><Switch /></el-icon>&nbsp; Swap WCORE</a
      >
      <ConvertToken @btn-click="sliderVisibile = false" />
    </div>
  </van-popup>
  <ApplyToken v-model="applyTokenVisible" />

  <el-dialog v-model="fauectVisibile" width="320" :title="$t('Mint.Title')">
    <div class="fauect_dialog_wrap_tips">
      {{ $t('Mint.Tips') }}
    </div>
    <div class="mt-3 bg-neutral-800 px-3 py-2.5 rounded text-slate-300">
      {{ maskAccount }}
    </div>
    <template #footer>
      <template v-if="isConnect">
        <el-button
          v-if="![bsc.id, bscTestnet.id].includes(chainId as any)"
          @click="switchBSC"
          type="primary"
          class="w-full"
        >
          {{ $t('applyToken.switchBSC') }}
        </el-button>
        <el-button
          v-else
          type="primary"
          :disabled="hasClaimFauect || fauectTime == null"
          class="w-full"
          :loading="fauectTime == null"
          @click="handleClickClaimFauect"
        >
          <template v-if="hasClaimFauect">
            <van-count-down :time="time">
              <template #default="{ days, hours, minutes, seconds }">
                <div class="text-slate-500 tabular-nums">
                  {{ hours.toString().padStart(2, 0) }}:{{
                    minutes.toString().padStart(2, 0)
                  }}:{{ seconds.toString().padStart(2, 0) }}
                </div>
              </template>
            </van-count-down>
          </template>
          <template v-else>
            {{ `${$t('Mint.Mint')}  10,000 $ZEBRA` }}
          </template>
        </el-button>
      </template>
      <el-button
        type="primary"
        class="w-full"
        v-else
        @click="handleClickConnectWallet"
      >
        {{ $t('Header.ConnectWallet') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 60px;
  padding: 0 12px;
  background: #101010;
  // border-bottom: 1px solid #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_l {
    display: flex;
    align-items: center;
    > a {
      height: 24px;
      margin-left: 12px;
      display: block;
      > img {
        width: 82px;
      }
    }
    > span {
      display: block;
      height: 20px;
      > img {
      }
    }
  }
  &_r {
    display: flex;
    &_wallet {
      .connect_wallet {
        min-width: 84px;
        padding: 0 8px;
        height: 32px;
        background: #2c2f38;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        > span {
          font-size: 12px;
          line-height: 16px;
          font-family: Inter Bold;
          color: #fff;
          margin-left: 5px;
        }
      }
      .connected_wallet {
        padding: 0 8px;
        height: 32px;
        border-radius: 4px;
        background: #2c2f38;
        display: flex;
        align-items: center;
        gap: 8px;
        > span {
          font-size: 12px;
          line-height: 16px;
          font-family: Inter Bold;
          color: #fff;
        }
        .arrow {
          font-size: 10px;
          color: var(--color-gray-2);
        }
      }
    }
    &_network {
      margin-left: 12px;
      padding: 0 8px;
      height: 32px;
      border-radius: 4px;
      background: #2c2f38;
      display: flex;
      align-items: center;
      &_c {
        width: 1px;
        height: 12px;
        background: #8e95a9;
        margin: 0 8px;
      }
      .language_item,
      .network_item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 2px 8px;
        > img {
          width: 20px;
        }
        > span {
          margin-left: 10px;
          color: #6a728a;
          flex: 1;
          margin-right: 8px;
        }
        .correct {
          opacity: 0;
        }
        &_active {
          > span {
            color: #fff !important;
          }
          .correct {
            opacity: 1;
          }
        }
      }

      .el-dropdown-link {
        img {
          width: 20px;
        }
      }
    }
  }
}
</style>

<style lang="less">
.header_r_network2 {
  .el-dropdown__popper.el-popper {
    border: none !important;
  }
  .el-dropdown-menu {
    background: #1a1c23;
    box-shadow: 0 7px 20px 0 rgba(13, 31, 66, 0.07);
    border-radius: 3px;
    border: none !important;
  }
  .el-popper__arrow {
    display: none !important;
  }
  .el-dropdown-menu__item:not(.is-disabled):focus {
    background: #1a1c23 !important;
    color: #6a728a;
  }
}
.slider_dialog {
  position: relative;
  padding: 40px 12px;

  > h3 {
    height: 52px;
    color: #fff;
    font-family: Inter Bold;
    font-size: 20px;
    line-height: 52px;
  }
  > div {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    > a {
      height: 56px;
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      line-height: 56px;
      display: flex;
      align-items: center;
      > svg {
        margin-left: 2px;
      }
    }
    .plan-link {
      border-radius: 4px;
      color: white;
      font-size: 14px;
      padding: 2px 12px;
      display: flex;
      height: 38px;
      line-height: 1;
      align-items: center;
      gap: 2px;
      border-width: 1px;
      border-style: solid;
      transition: all 0.2s ease-in-out;
      align-self: start;
      &:hover {
        color: var(--el-color-primary);
      }
    }
    .router-link-exact-active {
      color: #00e3a5 !important;
      border-radius: 8px;
    }
  }
}
</style>
