import BTCLogo from '../../src/assets/images/tokens/BTC.svg';
import ETHLogo from '../../src/assets/images/tokens/ETH.svg';
import DXYLogo from '../../src/assets/images/tokens/DXY.svg';
import NAS100Logo from '../../src/assets/images/tokens/NAS100.svg';
import SPX500Logo from '../../src/assets/images/tokens/SPX500.svg';
import XAGUSDLogo from '../../src/assets/images/tokens/XAGUSD.svg';
import XAUUSDLogo from '../../src/assets/images/tokens/XAUUSD.svg';
import US30Logo from '../../src/assets/images/tokens/US30.svg';
import IGULogo from '../../src/assets/images/tokens/IGU.svg';
import TSLALogo from '../../src/assets/images/tokens/TSLA.svg';
import IOSTLogo from '../../src/assets/images/tokens/IOST.png';
import UNKNOWLogo from '../../src/assets/images/tokens/UNKNOW.svg';
import SOLLogo from '../../src/assets/images/tokens/SOL.svg';
import ATOMLogo from '../../src/assets/images/tokens/ATOM.svg';
import BNBLogo from '../../src/assets/images/tokens/BNB.svg';
import DOTLogo from '../../src/assets/images/tokens/DOT.svg';
import ARBLogo from '../../src/assets/images/tokens/ARB.svg';
import OPLogo from '../../src/assets/images/tokens/OP.svg';
import AAVELogo from '../../src/assets/images/tokens/AAVE.svg';
import MKRLogo from '../../src/assets/images/tokens/MKR.svg';
import APELogo from '../../src/assets/images/tokens/APE.svg';
import ENSLogo from '../../src/assets/images/tokens/ENS.svg';
import GDXLogo from '../../src/assets/images/tokens/GDX.svg';
import ZEEPLogo from '@/assets/images/tokens/ZEEP.svg';
import ORDILogo from '@/assets/images/tokens/ORDI.svg';
import EOSLogo from '@/assets/images/tokens/eos.svg';
import SATSLogo from '@/assets/images/tokens/1000SATS.svg';
import RATSLogo from '@/assets/images/tokens/RATS.svg';
import ELTLogo from '@/assets/images/tokens/ELT.svg';
import BRCILogo from '@/assets/images/tokens/BRCI.svg';
import CoreLogo from '@/assets/images/tokens/Core.svg';
import DOGELogo from '@/assets/images/tokens/DOGE.svg';
import WLDLogo from '@/assets/images/tokens/WLD.svg';
import LINKLogo from '@/assets/images/tokens/LINK.svg';
import AKILogo from '@/assets/images/tokens/AKI.svg';
import TRUMPLogo from '@/assets/images/tokens/TRUMP.png';
import GMELogo from '@/assets/images/tokens/GME.png';
import NOTCOINLogo from '@/assets/images/tokens/NOTCOIN.png';
import ACMLogo from '@/assets/images/tokens/ACM.png';
import ASRLogo from '@/assets/images/tokens/ASR.png';
import ATMLogo from '@/assets/images/tokens/ATM.png';
import BARLogo from '@/assets/images/tokens/BAR.png';
import CHZLogo from '@/assets/images/tokens/CHZ.png';
import CITYLogo from '@/assets/images/tokens/CITY.png';
import JUVLogo from '@/assets/images/tokens/JUV.png';
import LAZIOLogo from '@/assets/images/tokens/LAZIO.png';
import PORTOLogo from '@/assets/images/tokens/PORTO.png';
import PSGLogo from '@/assets/images/tokens/PSG.png';
import SANTOSLogo from '@/assets/images/tokens/SANTOS.png';
// /Users/charles/Downloads/logo/WIF.png /Users/charles/Downloads/logo/BONK.png /Users/charles/Downloads/logo/FLOKI.png /Users/charles/Downloads/logo/PEPE.png /Users/charles/Downloads/logo/RATS.png
import WIFLogo from '@/assets/images/tokens/WIF.png';
import BONKLogo from '@/assets/images/tokens/BONK.png';
import FLOKILogo from '@/assets/images/tokens/FLOKI.png';
import PEPELogo from '@/assets/images/tokens/PEPE.png';
import ZKLogo from '@/assets/images/tokens/ZK.svg';
import ZEBRALogo from '@/assets/images/tokens/ZEBRA.svg';
import NewZEEPLogo from '@/assets/images/tokens/new-zeep.svg';
import ZCCLogo from '@/assets/images/tokens/ZCC.svg';

import {
  erc20ABI,
  getPublicClient,
  prepareWriteContract,
  WaitForTransactionArgs,
  writeContract,
} from '@wagmi/core';
import { toWei } from '@/web3/utils';
import SLDPerpetualABI from '@/web3/abis/SLDPerpetual.json';
import { storeToRefs } from 'pinia';
import { useCommonStore } from '@/store';
import { parsingException } from '@/utils';
import {
  CallParameters,
  hexToString,
  WaitForTransactionReceiptReturnType,
} from 'viem';
import axios from 'axios';
const tokensLogo = {
  BTC: BTCLogo,
  ETH: ETHLogo,
  DXY: DXYLogo,
  NAS100: NAS100Logo,
  SPX500: SPX500Logo,
  XAG: XAGUSDLogo,
  XAU: XAUUSDLogo,
  XAGUSD: XAGUSDLogo,
  XAUUSD: XAUUSDLogo,
  US30: US30Logo,
  IGU: IGULogo,
  TSLA: TSLALogo,
  IOST: IOSTLogo,
  SOL: SOLLogo,
  ATOM: ATOMLogo,
  BNB: BNBLogo,
  DOT: DOTLogo,
  ARB: ARBLogo,
  OP: OPLogo,
  AAVE: AAVELogo,
  MKR: MKRLogo,
  APE: APELogo,
  ENS: ENSLogo,
  GDX: GDXLogo,
  ZEEP: ZEEPLogo,
  BZEEP: ZEEPLogo,
  EZEEP: ZEEPLogo,
  TRUMP: TRUMPLogo,
  GME: GMELogo,
  NOT: NOTCOINLogo,
  ACM: ACMLogo,
  ASR: ASRLogo,
  ATM: ATMLogo,
  BAR: BARLogo,
  CHZ: CHZLogo,
  CITY: CITYLogo,
  JUV: JUVLogo,
  LAZIO: LAZIOLogo,
  PORTO: PORTOLogo,
  PSG: PSGLogo,
  SANTOS: SANTOSLogo,
  WIF: WIFLogo,
  BONK: BONKLogo,
  FLOKI: FLOKILogo,
  PEPE: PEPELogo,
  ZK: ZKLogo,
  '0XE46C5EA6DA584507EAF8DB2F3F57D7F578192E13': ZEEPLogo,
  '0XD6EE9E6BE560D8AED6CAF1BACF58603FA4B7E475': ZEEPLogo,
  '0X5D26DEA980716E4ABA19F5B73EB3DCCE1889F042': ZEEPLogo,
  '0X6D9DADCB0D6E5D10FB082B55C033E7DF6DFCEE94': ZEEPLogo,
  '0XE68A3CE8BEA4883F340C7121428882A2A210CD38': ZEEPLogo,
  '0XDEB93041BDB9893E9AE1A487E62AAA2002B4629D': ZEEPLogo,
  '0XECC1104F3BA8209F48C70B6420E997BD5EE7530F': ZEEPLogo,
  '0XA346963BE84A215BCE16FED8AAC0E24ECA74B25E': ZEEPLogo,
  '0X55CBAC75C1AF769EB7FD37D27A5CB6437EB29ABB': ZEEPLogo,
  '0X49FDEA2192B04E54E6D1CB5E3B3B996BAA6F621F': ZEEPLogo,
  '0X1281E326C6E4413A98DAFBD0D174A4AE07FF4223': ZEEPLogo,
  '0XC00592AA41D32D137DC480D9F6D0DF19B860104F': EOSLogo,
  '0X1A7E49125A6595588C9556F07A4C006461B24545': AKILogo,
  '0X29AD5DC05CCE47E77460B0E9A3DDF8FF838150FD': ZEBRALogo,
  '0XCA373BA20534A59EBBBD3BE141D8ECAB87300AD7': NewZEEPLogo,
  '0XEDD41B672765CE48416815DBF74595D2EE5B240F': NewZEEPLogo,
  '0X83EF9BE4A2EB8E43243B1025A9F608F5FFB8FA60': ZCCLogo,
  WEOS: EOSLogo,
  ORDI: ORDILogo,
  ELT: ELTLogo,
  EOS: EOSLogo,
  '1000SATS': SATSLogo,
  RATS: RATSLogo,
  'BRC-I': BRCILogo,
  CORE: CoreLogo,
  '0X40375C92D9FAF44D2F9DB9BD9BA41A3317A2404F': CoreLogo,
  '0XEB19D4B80F106A9EEDA364373CEE8438B2B6B8FC': CoreLogo,
  DOGE: DOGELogo,
  WLD: WLDLogo,
  LINK: LINKLogo,
  UNKNOW: UNKNOWLogo,
};
export default tokensLogo;

export const getTokenLogo = (name) => {
  if (!name) return tokensLogo['UNKNOW'];
  if (tokensLogo[name.toUpperCase()]) {
    return tokensLogo[name.toUpperCase()];
  } else {
    return tokensLogo['UNKNOW'];
  }
};

export type WaitForTransactionResult = WaitForTransactionReceiptReturnType;

export async function waitForTransaction({
  chainId,
  confirmations = 1,
  hash,
  onReplaced,
  timeout = 0,
}: WaitForTransactionArgs): Promise<WaitForTransactionResult> {
  const publicClient = getPublicClient({ chainId });

  const receipt = await publicClient.waitForTransactionReceipt({
    hash,
    confirmations,
    onReplaced,
    timeout,
  });
  if (receipt.status === 'reverted') {
    const txn = await publicClient.getTransaction({
      hash: receipt.transactionHash,
    });
    const code = (await publicClient.call({
      ...txn,
      data: txn.input,
      gasPrice: txn.type !== 'eip1559' ? txn.gasPrice : undefined,
      maxFeePerGas: txn.type === 'eip1559' ? txn.maxFeePerGas : undefined,
      maxPriorityFeePerGas:
        txn.type === 'eip1559' ? txn.maxPriorityFeePerGas : undefined,
    } as CallParameters)) as unknown as string;
    const reason = hexToString(`0x${code.substring(138)}`);
    throw new Error(reason);
  }
  return receipt;
}
export async function tryCatchFunc(func: () => {}) {
  try {
    return await func();
  } catch (e) {
    console.log(Object.keys(e));
    submitError(e);
    e.oldMsg = e.message;
    e.message = parsingException(e);
    throw e;
  }
}

export function submitError(e: any) {
  axios.post('/api/v2/zeepr/nftErrorMsgInfo/addRecord', {
    errorMsg: e.message + '\n' + e.stack,
  });
}

/**
 * 获取平台可以用余额
 * @param address 地址
 * @param spender 授权地址
 * @param amount 授权数量
 */
export async function approve(
  address: string,
  spender: string,
  amount = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
) {
  await tryCatchFunc(async () => {
    const { request } = await prepareWriteContract({
      address,
      abi: erc20ABI,
      functionName: 'approve',
      args: [spender, amount],
    });
    const { hash } = await writeContract(request);
    await waitForTransaction({
      hash,
    });
  });
}

/**
 * 向平台充值
 * @param address 地址
 * @param amount 数量
 */
export async function tradeDeposit(address: string, amount: string | number) {
  await tryCatchFunc(async () => {
    const { request } = await prepareWriteContract({
      address,
      abi: SLDPerpetualABI,
      functionName: 'deposit',
      args: [toWei(amount)],
    });
    const { hash } = await writeContract(request);
    await waitForTransaction({
      hash,
    });
  });
}

/**
 * 向平台提现
 * @param address 地址
 * @param amount 数量
 */
export async function tradeWithdraw(address: string, amount: string | number) {
  const { marketPriceData } = storeToRefs(useCommonStore());
  await tryCatchFunc(async () => {
    const otherData = Object.values<{
      price: string;
      signature: string;
      deadline: number;
      symbol: string;
    }>(marketPriceData.value).reduce<{
      prices: string[];
      signs: string[];
      deadline: number[];
      names: string[];
    }>(
      (acc, cur) => {
        acc.prices.push(toWei(cur.price));
        acc.signs.push('0x' + cur.signature);
        acc.deadline.push(cur.deadline);
        acc.names.push(cur.symbol);
        return acc;
      },
      {
        prices: [],
        signs: [],
        deadline: [],
        names: [],
      }
    );
    const { request } = await prepareWriteContract({
      address,
      abi: SLDPerpetualABI,
      functionName: 'withdraw',
      args: [
        toWei(amount),
        otherData.names,
        otherData.prices,
        otherData.deadline,
        otherData.signs,
      ],
    });
    const { hash } = await writeContract(request);
    await waitForTransaction({
      hash,
    });
  });
}
