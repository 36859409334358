import MessageLoading from '../../../assets/images/common/message_loading.svg';
import MessageError from '../../../assets/images/common/message_error.svg';
import MessageSuccess from '../../../assets/images/common/message_success.svg';
import Progress from '../progress/index.vue';
import { h } from 'vue';
import i18n from '../../../locales';
const Toast = ({
  type = 'info',
  message,
  duration = 3000,
  showClose = true,
}) => {
  if (type == 'loading') {
    return {
      message: h(
        'div',
        {
          class: 'loading-notify',
        },
        [
          h('img', { class: 'loading-notify-icon', src: MessageLoading }),
          h(
            'span',
            { class: 'loading-notify-message' },
            i18n.global.t('Message.Loading')
          ),
        ]
      ),
      type: 'info' as any,
      duration: 0,
      showClose: showClose,
      customClass: 'loading-notification',
    };
  }
  if (type == 'success') {
    return {
      message: h(
        'div',
        {
          class: 'success-notify',
        },
        [
          h('img', { class: 'success-notify-icon', src: MessageSuccess }),
          h('span', { class: 'success-notify-message' }, message),
          h(Progress, {
            style: { marginTop: '6px' },
            duration: duration,
          }),
        ]
      ),
      type: 'success' as any,
      duration: duration,
      showClose: showClose,
      customClass: 'success-notification',
    };
  }
  if (type == 'error') {
    return {
      message: h(
        'div',
        {
          class: 'error-notify',
        },
        [
          [
            h('img', { class: 'error-notify-icon', src: MessageError }),
            h('span', { class: 'error-notify-message' }, message),
            h(Progress, {
              style: { marginTop: '6px' },
              duration: duration,
            }),
          ],
        ]
      ),
      type: 'error' as any,
      duration: duration,
      showClose: showClose,
      customClass: 'error-notification',
    };
  }
};
export default Toast;
