import axios from 'axios';
import AWS from 'aws-sdk';
import { Upload } from '@aws-sdk/lib-storage';
import { CompleteMultipartUploadCommandOutput, S3 } from '@aws-sdk/client-s3';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { concat } from 'lodash-es';

export async function getAWS() {
  const {
    data: {
      code,
      msg,
      result: { data, bucketRegion, bucketName, frontUrl },
    },
  } = await axios.get('/api/v2/sys/sts');
  if (code !== 0) {
    throw new Error(msg);
  }
  return { ...data, bucketRegion, bucketName, frontUrl };
}

export async function awsUploadFile(files, rootDir) {
  const awsToken = await getAWS();
  const s3 = new S3({
    region: awsToken.bucketRegion,
    credentials: new AWS.Credentials(
      awsToken.accessKeyId,
      awsToken.secretAccessKey,
      awsToken.sessionToken
    ),
  });
  const uploadFiles = concat([], files);
  return await uploadFiles.reduce(async (accP, file) => {
    const acc = await accP;
    let blob;
    let type;
    let name;
    if (file.blob) {
      blob = file.blob;
      type = file.blob.type;
      name = file.name;
    } else {
      blob = file;
      type = file.type;
      name = `${encodeURIComponent(
        dayjs().format('YYYYMMDD')
      )}/${encodeURIComponent(nanoid())}.${
        file.name.split('.')[file.name.split('.').length - 1]
      }`;
    }
    try {
      const data = (await new Upload({
        client: s3,
        params: {
          Bucket: awsToken.bucketName,
          Key: `${rootDir}/${name}`,
          Body: blob,
          type,
          CacheControl: 'no-cache',
          Expires: new Date(),
          ACL: 'public-read',
          'Access-Control-Allow-Origin': '*',
        },
      }).done()) as CompleteMultipartUploadCommandOutput & { url: string };
      data.url = (awsToken.frontUrl || '') + data.Key;
      console.log(data);
      acc.push(data);
    } catch (e) {
      throw e;
    }
    return acc;
  }, Promise.resolve([]));
}
