import { useUserStore } from '@/store';
import { ContractObject } from '@/web3/address';
import { syncGetNetWorkName } from '@/web3/utils';
import { storeToRefs } from 'pinia';
import { Address } from 'viem';
import { computed, onMounted, ref, watch } from 'vue';
import { readContract } from '@wagmi/core';
import FauectABI from '@/web3/abis/Fauect.json';
import { useDebounceFn } from '@vueuse/core';

export function useFauectTime(): [typeof time, typeof debounceFunction] {
  const { account, chainId } = storeToRefs(useUserStore());
  const time = ref<number>(null);

  const address = computed(() => {
    return ContractObject[syncGetNetWorkName(chainId.value)]?.FauectAddress;
  });

  async function fetchDate() {
    if (!address.value || !account.value) {
      time.value = null;
      return;
    }

    const data = await readContract({
      address: address.value as Address,
      abi: FauectABI,
      functionName: 'users',
      args: [account.value],
      chainId: chainId.value,
    });
    time.value = Number(data) * 1000 + 24 * 60 * 60 * 1000;
  }

  const debounceFunction = useDebounceFn(fetchDate, 300);

  watch(account, () => {
    time.value = null;
    debounceFunction();
  });

  watch(address, () => {
    time.value = null;
    debounceFunction();
  });

  onMounted(() => {
    debounceFunction();
  });

  return [time, debounceFunction];
}
