import { defineStore } from "pinia";

const useKlineStore = defineStore("useKlineStore", {
  state: () => ({
    changeData: [],
  }),
  actions: {
    setChangeData(data: any[]) {
      this.changeData = data;
    }
  }
});

export default useKlineStore;
