import { Address } from 'viem';
import { tryCatchFunc } from '../tokens';
import { ContractObject } from '../address';
import { syncGetNetWorkName } from '../utils';
import ApplyTokenABI from '../abis/ApplyToken.json';
import { waitForTransaction, writeContract } from '@wagmi/core';

export async function addToken(
  tokenAddress: string,
  tokenName: string,
  logo: string,
  paymentCoinAddress: Address,
  chainId: number
) {
  return await tryCatchFunc(async () => {
    const address = ContractObject[syncGetNetWorkName()].ApplyTokenAddress;

    if (!address) {
      throw new Error('ApplyTokenAddress is not found');
    }
    console.table({
      address,
      paymentCoinAddress,
      tokenAddress,
      chainId,
      tokenName,
      logo,
    });
    const { hash } = await writeContract({
      address,
      abi: ApplyTokenABI,
      functionName: 'addCoin',
      args: [paymentCoinAddress, tokenAddress, chainId, tokenName, logo],
    });

    await waitForTransaction({
      hash,
    });
  });
}
