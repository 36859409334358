import {
  arbitrum,
  arbitrumGoerli,
  arbitrumSepolia,
  bsc,
  bscTestnet,
  eos,
  eosTestnet,
  polygon,
  polygonMumbai,
  coreDao,
  zkFair,
  zkFairTestnet,
  manta,
} from 'viem/chains';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/vue';
import { JoyIdConnector } from '@joyid/wagmi';
import JoyId from '@/assets/images/common/joyid.svg';
import { defineChain } from 'viem/utils';

console.log(__APP_VERSION__);
// @ts-ignore
arbitrumGoerli.rpcUrls.public['http'] = [
  'https://arbitrum-goerli.publicnode.com',
];
// @ts-ignore
arbitrumGoerli.rpcUrls.default['http'] = [
  'https://arbitrum-goerli.publicnode.com',
];
// @ts-ignore
// arbitrumSepolia.rpcUrls.public['http'] = [
//   'https://arbitrum-sepolia.publicnode.com',
// ];
// @ts-ignore
// arbitrumSepolia.rpcUrls.default['http'] = [
//   'https://arbitrum-sepolia.publicnode.com',
// ];

export const coreDaoTestnet = defineChain({
  id: 1115,
  name: 'Core Dao Test',
  nativeCurrency: {
    decimals: 18,
    name: 'Core',
    symbol: 'CORE',
  },
  rpcUrls: {
    default: { http: ['https://rpc.test.btcs.network'] },
    public: {
      http: ['https://rpc.test.btcs.network'],
      webSocket: [],
    },
  },
  blockExplorers: {
    default: {
      name: 'CoreDao',
      url: 'https://scan.test.btcs.network',
    },
  },
  testnet: true,
  network: 'Core Test',
});

/**
 * Network name: Merlin Mainnet
 * RPC URL: https://rpc.merlinchain.io
 * Chain ID: 4200
 * Currency symbol: BTC
 * Block explorer URL: https://scan.merlinchain.io
 */
export const merlin = defineChain({
  id: 4200,
  name: 'Merlin',
  nativeCurrency: {
    decimals: 18,
    name: 'BTC',
    symbol: 'BTC',
  },
  rpcUrls: {
    default: { http: ['https://rpc.merlinchain.io'] },
    public: {
      http: ['https://rpc.merlinchain.io'],
      webSocket: [],
    },
  },
  blockExplorers: {
    default: {
      name: 'Merlin',
      url: 'https://scan.merlinchain.io',
    },
  },
  testnet: false,
  network: 'Merlin Mainnet',
});

/**
 * Network name: Merlin Testnet
 * RPC URL: https://testnet-rpc.merlinchain.io
 * Chain ID: 686868
 * Currency symbol: BTC
 * Block explorer URL: https://testnet-scan.merlinchain.io
 */
export const merlinTestnet = defineChain({
  id: 686868,
  name: 'Merlin Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BTC',
    symbol: 'BTC',
  },
  rpcUrls: {
    default: { http: ['https://testnet-rpc.merlinchain.io'] },
    public: {
      http: ['https://testnet-rpc.merlinchain.io'],
      webSocket: [],
    },
  },
  blockExplorers: {
    default: {
      name: 'Merlin Testnet',
      url: 'https://testnet-scan.merlinchain.io',
    },
  },
  testnet: true,
  network: 'Merlin Testnet',
});

export const mantaSepolia = defineChain({
  id: 3_441_006,
  name: 'Manta Sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://pacific-rpc.sepolia-testnet.manta.network/http'],
    },
    public: {
      http: ['https://pacific-rpc.sepolia-testnet.manta.network/http'],
      webSocket: [],
    },
  },
  blockExplorers: {
    default: {
      name: 'Manta Sepolia',
      url: 'https://pacific-explorer.sepolia-testnet.manta.network',
    },
  },
  testnet: true,
  network: 'Manta Sepolia',
});

export const chains = [
  arbitrum,
  arbitrumGoerli,
  arbitrumSepolia,
  eos,
  eosTestnet,
  polygon,
  polygonMumbai,
  coreDao,
  coreDaoTestnet,
  bsc,
  bscTestnet,
  merlin,
  merlinTestnet,
  zkFair,
  zkFairTestnet,
  manta,
  mantaSepolia,
];

const projectId = 'ffc6158a6a2081843d15fceb70155940';
const metadata = {
  name: 'Zeepr',
  description:
    'Decentralized perpetual protocol that supports the trading of mainstream financial assets with crypto collaterals',
  url: 'https://app.zeepr.io/',
  icons: ['https://app.zeepr.io/crypto-to-financial-assets.jpg'],
};

export const joyidConnector = new JoyIdConnector({
  chains: chains as any[],
  options: {
    // name of your app
    name: 'JoyID',
    // logo of your app
    logo: window.location.origin + '/favicon-192.png',
    // JoyID app url that your app is integrated with
    joyidAppURL: 'https://app.joy.id',
  },
});
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  enableCoinbase: false,
});

wagmiConfig.connectors.push(joyidConnector);

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  connectorImages: {
    joyid: JoyId,
  },
  featuredWalletIds: [
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
  ],
});
