<script lang="ts" setup>
import Header from './header/index.vue';
import { useCommonStore, useUserStore, useWeb3Store } from '@/store';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { fromWei } from '@/web3/utils';
import { useRoute } from 'vue-router';
import { allSymbolObject } from '@/config/symbol';
import axios from 'axios';
import { useAllParams } from '@/hooks/v2/platform';
import Notice from './header/notice.vue';

const route = useRoute();
const commonStore = useCommonStore();
const userStore = useUserStore();
useAllParams();

const { allSymbolArray, marketPriceList, marketPriceData } =
  storeToRefs(commonStore);
const { chainId, account } = storeToRefs(userStore);
const timer = ref();
const initChainId = async () => {
  userStore.setAccountAndChain();
  userStore.setChain();
  commonStore.refreshAllSymbolArray();
};

const initSymbolLatestPrice = async () => {
  const names = ['BTC', 'ELT', 'ORDI', 'EOS', '1000SATS', 'RATS', 'BRC-I'].map(
    (symbol) => {
      return allSymbolObject[symbol]?.kline_name;
    }
  );
  try {
    const {
      data: {
        code,
        result: { data },
      },
    } = await axios.get('/kline/api/price/signatures', {
      params: {
        symbols: names.join(','),
      },
    });
    if (code === 200) {
      data
        .filter((item) => item)
        .forEach((item) => {
          const price = fromWei(item.price);
          marketPriceData.value = {
            ...marketPriceData.value,
            [item.symbol]: { ...item, price },
          };
          marketPriceList.value = {
            ...marketPriceList.value,
            [item.symbol]: price,
          };
        });
    }
  } catch (e) {
    console.error(e);
  }
};

watch(allSymbolArray, () => {
  initSymbolLatestPrice();
});

watch(
  () => [account.value, chainId.value],
  () => {
    initChainId();
    if (allSymbolArray.value.length > 0) {
      initSymbolLatestPrice();
    }
  }
);
onMounted(() => {
  initChainId();
  initSymbolLatestPrice();
  timer.value = setInterval(() => {
    initSymbolLatestPrice();
  }, 1000);
});
onBeforeUnmount(() => {
  clearInterval(timer.value);
});
</script>

<template>
  <div class="layout">
    <Header />
    <div class="bodyer">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component
            :key="route.name"
            :is="Component"
            v-if="route.meta.keepAlive"
          />
        </keep-alive>
        <component
          :key="route.name"
          :is="Component"
          v-if="!route.meta.keepAlive"
        />
      </router-view>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<style lang="less" scoped>
.layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .bodyer {
    flex: 1;
    overflow: auto;
    @apply bg-black;
  }
}
</style>
