import { ContractObject } from '../address';
import { tryCatchFunc } from '../tokens';
import { syncGetNetWorkName } from '../utils';
import ConvertTokenABI from '../abis/ConvertToken.json';
import { waitForTransaction, writeContract } from '@wagmi/core';

export async function convertToken() {
  await tryCatchFunc(async () => {
    // exchange
    const address = ContractObject[syncGetNetWorkName()]?.ConvertTokenAddress;
    if (!address) {
      throw new Error('address is not found');
    }
    const { hash } = await writeContract({
      address,
      abi: ConvertTokenABI,
      functionName: 'exchange',
    });

    await waitForTransaction({
      hash,
    });
  });
}
