import { defineStore } from 'pinia';
import en_US from '@/assets/images/header/en_US.svg';
import zh_TW from '@/assets/images/header/zh_TW.svg';
import tr_TR from '@/assets/images/header/Turkey.svg';
import ko_KR from '@/assets/images/header/koKR.svg';
import vi_VN from '@/assets/images/header/viFlag.svg';
import { allSymbolArrayFn } from '@/config/symbol';
import useUserStore from './user';
const useCommonStore = defineStore('useCommonStore', {
  state: () => {
    let symbol = sessionStorage.getItem('symbol') || 'BTC';
    let allSymbolArray = [];
    return {
      refreshCount: 1,
      visibiliteSuccess: false,
      visibilitePending: false,
      visibiliteFailed: false,
      transactionHash: '',
      successText: 'Success',
      failedText: 'Success',
      messageTips: false,
      messageType: 'loading',
      messageText: '',
      currency:
        sessionStorage.getItem('currency') ||
        '0XCA373BA20534A59EBBBD3BE141D8ECAB87300AD7',
      symbol,
      marketPrice: '0',
      positionVolume: '0',
      openOrdersVolume: '0',
      ordersHistoryVolume: '0',
      tradeHistoryVolume: '0',
      marketPriceList: {},
      marketPriceData: {},
      marketStatusList: {
        BTC: true,
        NAS100: true,
        ETH: true,
        XAG: true,
        SPX500: true,
        XAU: true,
        US30: true,
        TSLA: true,
        ATOM: true,
        BNB: true,
        DOT: true,
        ARB: true,
        OP: true,
        AAVE: true,
        MKR: true,
        APE: true,
        ENS: true,
        SOL: true,
        ORDI: true,
        EOS: true,
        '1000SATS': true,
        RATS: true,
        'BRC-I': true,
        ELT: true,
        DOGE: true,
        WLD: true,
        LINK: true,
        TRUMP: true,
        GME: true,
        NOT: true,
        ACM: true,
        ASR: true,
        ATM: true,
        BAR: true,
        CHZ: true,
        CITY: true,
        JUV: true,
        LAZIO: true,
        PORTO: true,
        PSG: true,
        SANTOS: true,
        WIF: true,
        BONK: true,
        PEPE: true,
        FLOKI: true,
        ZK: true,
      },
      marketCloseTimeList: {},
      marketContList: {},
      lang: localStorage.getItem('locale') || 'en-US',
      currencyOptions: [],
      languageOptions: [
        {
          value: 'en-US',
          label: 'English',
          logo: en_US,
        },
        {
          value: 'zh-TW',
          label: '繁體中文',
          logo: zh_TW,
        },
        {
          value: 'tr-TR',
          label: 'Türkçe',
          logo: tr_TR,
        },
        {
          value: 'ko-KR',
          label: '한국어',
          logo: ko_KR,
        },
        {
          value: 'vi-VN',
          label: 'Tiếng Việt',
          logo: vi_VN,
        },
      ],
      allSymbolArray,
      symbolAddresses: {},
    };
  },
  getters: {},
  actions: {
    // 刷新计数器
    setRefreshCount() {
      this.refreshCount += 1;
    },
    // 设置语言
    setLanguage(lang) {
      this.lang = lang;
    },
    // 成功弹窗
    setSuccessDialog(visibiliteSuccess: boolean, successText: string = '') {
      this.visibiliteSuccess = visibiliteSuccess;
      this.successText = successText;
    },
    // 进行中弹窗
    setPendingDialog(visibilitePending: boolean, transactionHash: string = '') {
      this.visibilitePending = visibilitePending;
      this.transactionHash = transactionHash;
    },
    // 失败弹窗
    setFailedDialog(visibiliteFailed: boolean, failedText: string = '') {
      this.visibiliteFailed = visibiliteFailed;
      this.failedText = failedText;
    },
    setMessageTips(messageTips) {
      this.messageTips = messageTips;
    },
    setMessageTxt(messageText) {
      this.messageText = messageText;
    },
    setMessageType(messageType) {
      this.messageType = messageType;
    },
    // 设置当前保证金
    setCurrency(currency) {
      sessionStorage.setItem('currency', currency);
      this.currency = currency;
    },
    // 设置保证金列表
    setCurrencyOptions(currencyOptions) {
      if (!currencyOptions.find((item) => item.value === this.currency)) {
        this.currency = currencyOptions[0].value;
      }
      this.currencyOptions = currencyOptions;
    },
    // 设置当前保证金
    setSymbol(symbol) {
      sessionStorage.setItem('symbol', symbol);
      this.symbol = symbol;
    },
    // 市价
    setMarketPrice(marketPrice) {
      this.marketPrice = marketPrice;
    },
    // 持仓数量
    setPositionVolume(positionVolume) {
      this.positionVolume = positionVolume;
    },
    setOpenOrdersVolume(openOrdersVolume) {
      this.openOrdersVolume = openOrdersVolume;
    },
    setOrdersHistoryVolume(ordersHistoryVolume) {
      this.ordersHistoryVolume = ordersHistoryVolume;
    },
    setTradeHistoryVolume(tradeHistoryVolume) {
      this.tradeHistoryVolume = tradeHistoryVolume;
    },
    setMarketPriceList(marketPriceList) {
      this.marketPriceList = marketPriceList;
    },
    setMarketStatusList(marketStatusList) {
      this.marketStatusList = marketStatusList;
    },
    setMarketCloseTimeList(marketCloseTimeList) {
      this.marketCloseTimeList = marketCloseTimeList;
    },
    setMarketContList(marketContList) {
      this.marketContList = marketContList;
    },
    async refreshAllSymbolArray() {
      const userStore = useUserStore();
      this.allSymbolArray = await allSymbolArrayFn(userStore.chainId);
      if (
        this.allSymbolArray.length > 0 &&
        !this.allSymbolArray.includes(this.symbol)
      ) {
        if (userStore.isConnect) {
          this.setSymbol(this.allSymbolArray[0]);
        } else {
          this.symbol = this.allSymbolArray[0];
        }
      } else {
        this.setSymbol(sessionStorage.getItem('symbol') || 'BTC');
      }
    },
  },
});
export default useCommonStore;
