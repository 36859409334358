import { getNetWorkName } from '@/web3/utils';
import { ContractObject } from '@/web3/address';

export const allSymbolObject = {
  BTC: {
    api_name: 'BINANCE:BTCUSDT',
    show_name: 'BTCUSD',
    contract_name: 'BTC',
    kline_name: 'BTC',
    id: 1001,
    type: 'crypto',
    decimal: 2,
  },
  'BRC-I': {
    api_name: 'BINANCE:BRC-I',
    show_name: 'BRC-IUSD',
    contract_name: 'BRC-I',
    kline_name: 'BRC-I',
    id: 1105,
    type: 'crypto',
    decimal: 2,
  },
  EOS: {
    api_name: 'BINANCE:EOS',
    show_name: 'EOSUSD',
    contract_name: 'EOS',
    kline_name: 'EOS',
    id: 1103,
    type: 'crypto',
    decimal: 4,
  },
  ORDI: {
    api_name: 'BINANCE:ORDIUSDT',
    show_name: 'ORDIUSD',
    contract_name: 'ORDI',
    kline_name: 'ORDI',
    id: 1101,
    type: 'crypto',
    decimal: 2,
  },
  '1000SATS': {
    api_name: 'BINANCE:1000SATS',
    show_name: '1000SATSUSD',
    contract_name: '1000SATS',
    kline_name: '1000SATS',
    id: 1101,
    type: 'crypto',
    decimal: 6,
  },
  RATS: {
    api_name: 'BINANCE:RATS',
    show_name: 'RATSUSD',
    contract_name: 'RATS',
    kline_name: 'RATS',
    id: 1104,
    type: 'crypto',
    decimal: 6,
  },
  ELT: {
    api_name: 'BINANCE:ELTUSDT',
    show_name: 'ELTUSD',
    contract_name: 'ELT',
    kline_name: 'ELT',
    id: 1102,
    type: 'crypto',
    decimal: 2,
  },
  ETH: {
    api_name: 'BINANCE:ETHUSDT',
    show_name: 'ETHUSD',
    contract_name: 'ETH',
    kline_name: 'ETH',
    id: 1002,
    type: 'crypto',
  },
  // DXY: {
  //   api_name: "CAPITAL:DXY",
  //   show_name: "DXY",
  //   contract_name: "DXY",
  //   kline_name: "DXY",
  //   id: 1003,
  //   type: "index",
  // },
  NAS100: {
    api_name: 'FXCM:NAS100',
    show_name: 'NAS100',
    contract_name: 'NAS100',
    kline_name: 'NAS100',
    id: 1004,
    type: 'index',
  },
  SPX500: {
    api_name: 'FXCM:SPX500',
    show_name: 'SPX500',
    contract_name: 'SPX500',
    kline_name: 'SPX500',
    id: 1005,
    type: 'index',
  },
  XAG: {
    api_name: 'OANDA:XAG_USD',
    show_name: 'XAGUSD',
    contract_name: 'XAGUSD',
    kline_name: 'XAG',
    id: 1006,
    type: 'commodities',
  },
  XAU: {
    api_name: 'OANDA:XAU_USD',
    show_name: 'XAUUSD',
    contract_name: 'XAUUSD',
    kline_name: 'XAU',
    id: 1007,
    type: 'commodities',
  },
  XAGUSD: {
    api_name: 'OANDA:XAG_USD',
    show_name: 'XAGUSD',
    contract_name: 'XAGUSD',
    kline_name: 'XAG',
    id: 1006,
    type: 'commodities',
  },
  XAUUSD: {
    api_name: 'OANDA:XAU_USD',
    show_name: 'XAUUSD',
    contract_name: 'XAUUSD',
    kline_name: 'XAU',
    id: 1007,
    type: 'commodities',
  },
  US30: {
    api_name: 'FXCM:US30',
    show_name: 'US30',
    contract_name: 'US30',
    kline_name: 'US30',
    id: 1008,
    type: 'index',
  },
  TSLA: {
    api_name: 'TSLA',
    show_name: 'TSLA',
    contract_name: 'TSLA',
    kline_name: 'TSLA',
    id: 1009,
    type: 'stocks',
  },
  SOL: {
    api_name: 'SOLUSD',
    show_name: 'SOLUSD',
    contract_name: 'SOL',
    kline_name: 'SOL',
    id: 1010,
    type: 'crypto',
  },
  ATOM: {
    api_name: 'ATOMUSD',
    show_name: 'ATOMUSD',
    contract_name: 'ATOM',
    kline_name: 'ATOM',
    id: 1011,
    type: 'crypto',
  },
  BNB: {
    api_name: 'BNBUSD',
    show_name: 'BNBUSD',
    contract_name: 'BNB',
    kline_name: 'BNB',
    id: 1012,
    type: 'crypto',
  },
  DOGE: {
    api_name: 'DOGEUSD',
    show_name: 'DOGEUSD',
    contract_name: 'DOGE',
    kline_name: 'DOGE',
    id: 1020,
    type: 'crypto',
  },
  DOT: {
    api_name: 'DOTUSD',
    show_name: 'DOTUSD',
    contract_name: 'DOT',
    kline_name: 'DOT',
    id: 1013,
    type: 'crypto',
  },
  ARB: {
    api_name: 'ARBUSD',
    show_name: 'ARBUSD',
    contract_name: 'ARB',
    kline_name: 'ARB',
    id: 1014,
    type: 'crypto',
  },
  OP: {
    api_name: 'OPUSD',
    show_name: 'OPUSD',
    contract_name: 'OP',
    kline_name: 'OP',
    id: 1015,
    type: 'crypto',
  },
  WLD: {
    api_name: 'WLDUSD',
    show_name: 'WLDUSD',
    contract_name: 'WLD',
    kline_name: 'WLD',
    id: 1015,
    type: 'crypto',
  },
  LINK: {
    api_name: 'LINkUSD',
    show_name: 'LINKUSD',
    contract_name: 'LINK',
    kline_name: 'LINK',
    id: 1015,
    type: 'crypto',
  },
  AAVE: {
    api_name: 'AAVEUSD',
    show_name: 'AAVEUSD',
    contract_name: 'AAVE',
    kline_name: 'AAVE',
    id: 1016,
    type: 'crypto',
  },
  MKR: {
    api_name: 'MKRUSD',
    show_name: 'MKRUSD',
    contract_name: 'MKR',
    kline_name: 'MKR',
    id: 1017,
    type: 'crypto',
  },
  APE: {
    api_name: 'APEUSD',
    show_name: 'APEUSD',
    contract_name: 'APE',
    kline_name: 'APE',
    id: 1018,
    type: 'crypto',
  },
  ENS: {
    api_name: 'ENSUSD',
    show_name: 'ENSUSD',
    contract_name: 'ENS',
    kline_name: 'ENS',
    id: 1019,
    type: 'crypto',
  },
  TRUMP: {
    api_name: 'TRUMPUSD',
    show_name: 'TRUMPUSD',
    contract_name: 'TRUMP',
    kline_name: 'TRUMP',
    id: 1019,
    type: 'crypto',
  },
  GME: {
    api_name: 'GMEUSD',
    show_name: 'GMEUSD',
    contract_name: 'GME',
    kline_name: 'GME',
    id: 1019,
    type: 'crypto',
  },
  NOT: {
    api_name: 'NOTUSD',
    show_name: 'NOTUSD',
    contract_name: 'NOT',
    kline_name: 'NOT',
    id: 1019,
    type: 'crypto',
  },
  ACM: {
    api_name: 'ACMUSD',
    show_name: 'ACMUSD',
    contract_name: 'ACM',
    kline_name: 'ACM',
    id: 1019,
    type: 'crypto',
  },
  ASR: {
    api_name: 'ASRUSD',
    show_name: 'ASRUSD',
    contract_name: 'ASR',
    kline_name: 'ASR',
    id: 1019,
    type: 'crypto',
  },
  ATM: {
    api_name: 'ATMUSD',
    show_name: 'ATMUSD',
    contract_name: 'ATM',
    kline_name: 'ATM',
    id: 1019,
    type: 'crypto',
  },
  BAR: {
    api_name: 'BARUSD',
    show_name: 'BARUSD',
    contract_name: 'BAR',
    kline_name: 'BAR',
    id: 1019,
    type: 'crypto',
  },

  CHZ: {
    api_name: 'CHZUSD',
    show_name: 'CHZUSD',
    contract_name: 'CHZ',
    kline_name: 'CHZ',
    id: 1019,
    type: 'crypto',
  },
  CITY: {
    api_name: 'CITYUSD',
    show_name: 'CITYUSD',
    contract_name: 'CITY',
    kline_name: 'CITY',
    id: 1019,
    type: 'crypto',
  },
  JUV: {
    api_name: 'JUVUSD',
    show_name: 'JUVUSD',
    contract_name: 'JUV',
    kline_name: 'JUV',
    id: 1019,
    type: 'crypto',
  },
  LAZIO: {
    api_name: 'LAZIOUSD',
    show_name: 'LAZIOUSD',
    contract_name: 'LAZIO',
    kline_name: 'LAZIO',
    id: 1019,
    type: 'crypto',
  },
  PORTO: {
    api_name: 'PORTOUSD',
    show_name: 'PORTOUSD',
    contract_name: 'PORTO',
    kline_name: 'PORTO',
    id: 1019,
    type: 'crypto',
  },
  PSG: {
    api_name: 'PSGUSD',
    show_name: 'PSGUSD',
    contract_name: 'PSG',
    kline_name: 'PSG',
    id: 1019,
    type: 'crypto',
  },
  SANTOS: {
    api_name: 'SANTOSUSD',
    show_name: 'SANTOSUSD',
    contract_name: 'SANTOS',
    kline_name: 'SANTOS',
    id: 1019,
    type: 'crypto',
  },
  //   WIF: WIFLogo,
  // BONK: BONKLogo,
  // FLOKI: FLOKILogo,
  // PEPE: PEPELogo,
  WIF: {
    api_name: 'WIFUSD',
    show_name: 'WIFUSD',
    contract_name: 'WIF',
    kline_name: 'WIF',
    id: 1019,
    type: 'crypto',
  },
  BONK: {
    api_name: 'BONKUSD',
    show_name: 'BONKUSD',
    contract_name: 'BONK',
    kline_name: 'BONK',
    id: 1019,
    type: 'crypto',
    decimal: 6,
  },
  FLOKI: {
    api_name: 'FLOKIUSD',
    show_name: 'FLOKIUSD',
    contract_name: 'FLOKI',
    kline_name: 'FLOKI',
    id: 1019,
    type: 'crypto',
    decimal: 6,
  },
  PEPE: {
    api_name: 'PEPEUSD',
    show_name: 'PEPEUSD',
    contract_name: 'PEPE',
    kline_name: 'PEPE',
    id: 1019,
    type: 'crypto',
    decimal: 6,
  },
  ZK: {
    api_name: 'ZKUSD',
    show_name: 'ZKUSD',
    contract_name: 'ZK',
    kline_name: 'ZK',
    id: 1019,
    type: 'crypto',
  },
};
export const allSymbolArrayFn = async (
  chainId?: number
): Promise<Array<string>> => {
  const list = Object.keys(allSymbolObject).map(
    (key) => allSymbolObject[key].kline_name
  );
  let netWorkName = 'ARB';
  try {
    netWorkName = await getNetWorkName(chainId);
  } catch (e) {}
  return Array.from(
    new Set(
      list.filter((item) => {
        const UnderlyingAssets = item + 'PerpetualUnderlyingAsset';
        return ContractObject[netWorkName][UnderlyingAssets];
      })
    )
  );
};
