import {
  mainnet,
  arbitrum,
  bsc,
  opBNB,
  arbitrumGoerli,
  eos,
  eosTestnet,
  bscTestnet,
  opBNBTestnet,
  arbitrumSepolia,
  polygon,
  polygonMumbai,
  coreDao,
  zkFair,
  zkFairTestnet,
  manta,
  mantaTestnet,
} from '@wagmi/core/chains';
import {
  coreDaoTestnet,
  mantaSepolia,
  merlin,
  merlinTestnet,
} from './walletconnect';
export const ContractObject = {
  BSC: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // TRUMPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // GMEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // NOTPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // ACMPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // ASRPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // ATMPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // BARPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // CHZPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // CITYPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // JUVPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // LAZIOPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // PORTOPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // PSGPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    // SANTOSPerpetualUnderlyingAsset:
    //   '0xE7904A60961254E2185025558c37B7B03529373E',
    // WIF BONK PEPE FLOKI RATS
    WIFPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BONKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    FLOKIPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    PEPEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    RATSPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ZKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    NFTAddress: '0xf86ef3d215377cb4ea13ddd1ed5c9486a822cdea',
    NFTAddressV2: '0x500054758745537018648895c5d9d915a8b27044',
    WhitelistNFTAddress: '0x500054758745537018648895c5d9d915a8b27044',
    NFTBuyAddress: '0x500054758745537018648895c5d9d915a8b27044',

    PerpetualAddress: '0x501CE25728ed867bE3Fc3fF9682946a6BaeaD06C',
    PerpetualTrade: '0x81097f21004ed9cE0c4d96eC12323A163532A27f',
    PrivatePool: '0x096e9A8B7137bEBA3A043b800D3d227d5abB077a',
    Exchanger: '0xA018f56d576989a7B71869e945eabB3E043eFf76',
    perpetualProxy: '0x4703bEAce9d4e76BB86fBbBCf39a99e6940a2219',

    SwapAddress: '0x637Ed239a7E6E8951e3Bd036E726eA8eceecd362',
    BridgeSolanaAddress: '0xaEa1bfD83161a026203C016905Af0c1973598965',

    RewardAddress: '0xBc1eB4676c8cAa594658962C15FB7c96E9A394Fd',

    Stake: '0x38E044527Dc764Dbbea632F9F1D068339F60B874',

    ApplyTokenAddress: '0xf06ea8f31fda2dba244d40ddc9f4777d34ef78fa',

    FauectAddress: '0x29ad5dc05cce47e77460b0e9a3ddf8ff838150fd',

    ConvertTokenAddress: '0x0b7691ACd192224f94B7bba6c0B84f838bA396a9',
  },
  OPBNB: {
    ETHAggregator: '0x4cf7ddba1c9e52c41763980ed492a603a15d748a',
    ETHPerpetualUnderlyingAsset: '0x321d0d247d520ce8f7b3086bb444ae993ff9f668',
    BTCAggregator: '0x0b0194e7cbe4e72d337c065d5fdbdf8f869144d3',
    BTCPerpetualUnderlyingAsset: '0x734c8160db9208ee1d3e4044e902599b9fa6b25f',
    DXYAggregator: '0x3293671d86b0a0b4abc0236c39e88ff1489fb18b',
    DXYPerpetualUnderlyingAsset: '0x7386781f9d7458b543a004c5f7ea84b8d84e6c0d',
    NAS100Aggregator: '0x95e0f184524a72bba0a70ff54f712613db4fd25b',
    NAS100PerpetualUnderlyingAsset:
      '0xec2c3f777506f789df2f4affcc9b9e357cff2a0d',
    SPX500Aggregator: '0xb57517544e66ad369578abfa09a2fce5ff9af446',
    SPX500PerpetualUnderlyingAsset:
      '0x759ab3376b9e6f9f30095fcb6baec6ae50a5b3cb',
    XAGAggregator: '0x3dcf58fff565103d780966f9bcf752557bf8a89f',
    XAGPerpetualUnderlyingAsset: '0x1d51c7c4b8b0e246abf06b4af10b323dca48211a',
    XAUAggregator: '0xd5b92f2d35f0313fa4c7edd9380dd21301dca921',
    XAUPerpetualUnderlyingAsset: '0xc17b1138c43336e9c45c4b7b6b35500dd99625e9',
    US30Aggregator: '0xd8b4672e99e8bdd81494bed4db757dad6d5f8478',
    US30PerpetualUnderlyingAsset: '0xf8790ce9650c7bfcd03985cc70c92873a3bf4aa5',
    TSLAAggregator: '0x0373864035dcd77ce01cd84bb99707444ea88967',
    TSLAPerpetualUnderlyingAsset: '0x4e5bfecb69d1b92a12e643e3b09a5aa841947cc7',

    BuyBackAddrress: '0xd30a98ebb6c906236aeb69836d9cc7558f60fae5',
    MarketAgentAddrress: '0xf0f0dd60a144349d50b424cab8518605f4ea99b2',
    TradeFeeAddrress: '0xe8452E6E9828e5771ebFde40aBFB74261125302A',
    RiskFundAddrress: '0xa5a1d56064ae33d81512822869c1c9aa90aab432',
    AggregatorFactoryAddrress: '0x6ae7750139e89b07f33473291c98041d0f627050',
    OracleFactoryAddress: '0xf6AE0396E951151f54C2E115265fAcd2b3866a7C',

    MOOM: {
      Address: '0x10abbe2e9943a0986e5cf2012b45729c05606625',
      PerpetualPrivatePoolAddress: '0xa9732579ced42d4d300ecf6856273783327296d8',
      PerpetualAddress: '0xea307be042832afacbe28309ac62a01a94b3e7aa',
      PerpetualOrderBookAddress: '0x71585d80cd2edcd53c378c9a46bbe675dab7f22a',
      PerpetualTradeAgentAddress: '0xf158042b86acdd53e3d80aa6d059b7296ca26723',
      PerpetualTradeStationAddress:
        '0x825ec9f02d521cd0e7fa05ac524c89c80f644d80',
    },
  },
  ARB: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    NFTAddress: '0x37D8A51d9621041d6b9276ea8a835553b31698c7',
    NFTAddressV2: '0xE0B5e9f56Ec27e5D3937D6d60B77f426a649f15a',
    NFTBuyAddress: '0xE0B5e9f56Ec27e5D3937D6d60B77f426a649f15a',
    WhitelistNFTAddress: '0xE0B5e9f56Ec27e5D3937D6d60B77f426a649f15a',

    UnderlyingFactoryAddr: '0x6A1c8eE65C14267333223C10bF061426fB43d7c9',
    CustomDeploy: '0xeEE5837cbc4828C814a3F173c4290160455Fc660',
    AgentAddress: '0xa2791E80411895626b749d23354A752C6F7BFa3E',

    PerpetualAddress: '0x498E15a8916adc609248DA0CfA66BBC67c5Bc6Bd',
    PerpetualTrade: '0xEeB19a62921Df80474A51d1F90D8d897Fd32d0D1',
    PrivatePool: '0xbB0390Cf2586e9b0A4FAADF720aE188D140E9fd5',
    Exchanger: '0x3a7aB0025405F84Cfd04E033560fcd93664f4877',
    perpetualProxy: '0xC9D1042817b8A716565Ccb0Ab68ea9C5B4d72Fa4',

    SwapAddress: '0xcF8FA9af0400bd8823eC3aEcCC659849b0BBc4b2',
    BridgeSolanaAddress: '0x566Aa6eDf8430C9C048d23C011B7fB12dC45aa26',

    RewardAddress: '',
  },
  BSCTEST: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    NFTAddress: '0x68D7b07AC2287461FA855d1853D14c8C3D5b3bbE',
    NFTAddressV2: '0x68D7b07AC2287461FA855d1853D14c8C3D5b3bbE',
    WhitelistNFTAddress: '0x68D7b07AC2287461FA855d1853D14c8C3D5b3bbE',
    NFTBuyAddress: '0x68D7b07AC2287461FA855d1853D14c8C3D5b3bbE',

    PerpetualAddress: '0xa4e37716c57345D5FAB58273Be5F03D06ea6F7A7',
    PerpetualTrade: '0x193B6032C0D69f7efCE1de9c1eD0c504A362AD32',
    PrivatePool: '0x88A72cb97E89b9B4bBfAaE90F123f176C59F1Bbc',
    Exchanger: '0xa131eBd0A118582494873C8Ab1Ec162e668052f3',
    perpetualProxy: '0x164eCE0483DCCE58D9B41Dd1636579E89136c5FE',

    SwapAddress: '0x52CA41E7dd497b0e16858698F8874c081C1282e2',
    BridgeSolanaAddress: '0xBF3ef8Bc788dCd782827aB8f5a6e97E36b3E7f3f',

    RewardAddress: '0xd8679b178b1ee6bf57D710B6f3757e00Df77D994',

    ApplyTokenAddress: '0x421Bc9A7E8AAa8Bf9E1D0873F9b33C5cbA26451F',

    FauectAddress: '0x863d151Db45A32516c1ce2aCf54f871CBfEe1889',

    ConvertTokenAddress: '0xe846c283990D7b3bBCf2554Ad8C425475d16B169',
  },
  OPBNBTEST: {
    ETHAggregator: '0x095839249640665eb1f2b4311072089b6feda875',
    ETHPerpetualUnderlyingAsset: '0xa934e6630be0a059e5be53454dfd0ae4efd5f2d7',
    BTCAggregator: '0x5af8722e7f9e6aca65fe2322d74b97ef69ca31ce',
    BTCPerpetualUnderlyingAsset: '0x308473e108c4028613453fe461837d6455e5522b',
    DXYAggregator: '0x25a27bae8a9affce80c6f4775aa0167550a45062',
    DXYPerpetualUnderlyingAsset: '0x1b28105357afeca83b2a5e09fc8faed648c9cd87',
    NAS100Aggregator: '0xbad7b0f6c9637c01b0cf39f4cd8fda92cd32c734',
    NAS100PerpetualUnderlyingAsset:
      '0xc13cfb37d8e99976afa044394ac53fabef8925c2',
    SPX500Aggregator: '0x91ffe2a80775183a870aedba0bd749504cef7a2b',
    SPX500PerpetualUnderlyingAsset:
      '0x4e2673ddaf6424379c821c3894fac95ef1914d55',
    XAGAggregator: '0x8942f8557e16287b645805b7a650051367381b32',
    XAGPerpetualUnderlyingAsset: '0xcc84e28d1ca610f22b4c5e9534366dfb131f3fd8',
    XAUAggregator: '0x2a82531ae8aad3ddc6ce710e70d1361cd575749e',
    XAUPerpetualUnderlyingAsset: '0xa80a6239b454b090e1388f77ab15b35ac142a7df',
    US30Aggregator: '0x56049053a3b32daea9cddf5e66cbb219c7611db2',
    US30PerpetualUnderlyingAsset: '0xba35b4fec5d4de69398bfeb6a007a0856b4a8008',
    BuyBackAddrress: '0x9c12ac094fea4eb278bb84f699555036e6cb4e0b',
    MarketAgentAddrress: '0x911302fbc1258917b2659c1ee23ac1ae164eced1',
    TradeFeeAddrress: '0xe8452E6E9828e5771ebFde40aBFB74261125302A',
    RiskFundAddrress: '0xa5a1d56064ae33d81512822869c1c9aa90aab432',
    AggregatorFactoryAddrress: '0x42b5147589d59df2627060f0c3c56676265b949c',
    OracleFactoryAddress: '0x0fFe05853658137544c8C7948bA21B7EE2274B2b',
    MOOM: {
      Address: '0x10abbe2e9943a0986e5cf2012b45729c05606625',
      PerpetualPrivatePoolAddress: '0xdbb696d617d3633c9b9c458f120834f2b5f18cfb',
      PerpetualAddress: '0xa9732579ced42d4d300ecf6856273783327296d8',
      PerpetualOrderBookAddress: '0xea307be042832afacbe28309ac62a01a94b3e7aa',
      PerpetualTradeAgentAddress: '0x71585d80cd2edcd53c378c9a46bbe675dab7f22a',
      PerpetualTradeStationAddress:
        '0xf158042b86acdd53e3d80aa6d059b7296ca26723',
    },
  },
  ARBTEST: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    TRUMPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    GMEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    NOTPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ACMPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ASRPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ATMPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BARPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    CHZPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    CITYPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    JUVPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LAZIOPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    PORTOPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    PSGPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SANTOSPerpetualUnderlyingAsset:
      '0xE7904A60961254E2185025558c37B7B03529373E',

    NFTAddress: '0xf86ef3d215377cb4ea13ddd1ed5c9486a822cdea',
    NFTAddressV2: '0x4BA9c952E97c92EfA8c21148D695442A07115CaA',
    WhitelistNFTAddress: '0x4E52De57a13F8F039361Eb3b9D171c6C56D143Bb',
    NFTBuyAddress: '0xA0350fbeb1Ddb924D4Ea9f30d5511883d4d7581C',
    UnderlyingFactoryAddr: '0x8879E0eDf685b541EfCF3b910b2b18281999e5Fb',
    CustomDeploy: '0xF4eEA794023AD687CC25782456f8370Ca1C66296',
    AgentAddress: '0x4695abDa13DB4Cb8aDB2071353fd5Cd14Fe4a1c0',
    PerpetualAddress: '0x9F8820C8F3A3B26df0910618771DCd5039ceF179',
    PerpetualTrade: '0x297cA0ed3755448f8cfCc17Dc436875810B0eBCD',
    PrivatePool: '0x1c2D10633C78A47786759715d4618296D85D7cD1',
    Exchanger: '0x486Ee7D0a226C3c994B88FFa40422130D904bEb4',
    perpetualProxy: '0xBe8c3F531991fEeEB9C43473BdcC98C751E0d092',
    BridgeSolanaAddress: '0x4D0EA40dc631955E610aDB4801AbF4508041b610',

    RewardAddress: '0x4f31202FB78254c9bC47e278910Ce64b883Febd8',
    SwapAddress: '0x45a46a9E43a7FA541724678Ff5B1D0eed09D504d',

    Stake: '0x4F1bdcfff53816Ddf4Ebf80694ba8f65e302E759',
  },
  EOS: {
    NFTAddressV2: '0x605e24c524A4eD8054960c56ba12654970FA3bC4',
    WhitelistNFTAddress: '0x605e24c524A4eD8054960c56ba12654970FA3bC4',
    NFTBuyAddress: '0x605e24c524A4eD8054960c56ba12654970FA3bC4',
    UnderlyingFactoryAddr: '0xf6A3835BFB2cF8Cca9461d02CB446D8D1e3E448a',
    CustomDeploy: '0x26e72aFE83cc03adA2beD2068b528B40E30F156a',
    BTCPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    ETHPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    // EOSPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    // ORDIPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    // '1000SATSPerpetualUnderlyingAsset':
    //   '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    // RATSPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    AgentAddress: '0x398bDE9702ba58C4d88724B0BCF757CD779B2a2d',
    SwapAddress: '0x7171FF879bF96d3e0C89EB35cC65B481403d6059',

    PerpetualAddress: '0x0863C7BcdB6Cf6edd5dc4bbd181A8D555AedbfBd',
    PerpetualTrade: '0xfa70a75d009B42E04939c11632a5b27dc1e32017',
    PrivatePool: '0x33B638b6Df53a42166AD774467AA8AFa4300d237',
    Exchanger: '0x5610c4f83dAd42Ae79C4e1345F3A3361bADf3E66',
    perpetualProxy: '0xF7aeEa6229B5005148A603A2A4542ffB0643790e',
    BridgeSolanaAddress: '0xa1B8398F145724fcfDcD57CE5a7FC1BE191031ef',

    RewardAddress: '0x79685dF79961ACb2CF008A4845329722A9deA91e',
  },
  EOSTEST: {
    NFTAddressV2: '0x4C166eF9179C11b0549eF60d9681299fBeCD0891',
    WhitelistNFTAddress: '0x4C166eF9179C11b0549eF60d9681299fBeCD0891',
    NFTBuyAddress: '0x4C166eF9179C11b0549eF60d9681299fBeCD0891',

    BTCPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    EOSPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    // ORDIPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    // RATSPerpetualUnderlyingAsset: '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    // '1000SATSPerpetualUnderlyingAsset':
    //   '0x51110858e32D317A6fD7d2aD82363eb2404B50F9',
    AgentAddress: '0xbe737382Ac8CCD7D2051fEdE7F2d15bC49b32E75',
    SwapAddress: '0x76dEC1B354e044C62b0f7A3674Dc7AD87f3f6a76',
    BridgeSolanaAddress: '0x4B8b5faa3A5F04A3F5f360743225a552Ad4C8F10',

    RewardAddress: '',
  },
  POLYGON: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0xbe6BdC7D00b91719983b729d91e6F9080FdE5D3B',
    PerpetualTrade: '0x05BfF85b67FECc39cf7B712eC0D131fa16AC9201',
    PrivatePool: '0xCb9A02B704640ffcf43D6a8DAe5096fc8a44021c',
    Exchanger: '0x0EEc05B0272B8860D88874ea54e15f9fFE9CF4ac',
    perpetualProxy: '0xA018f56d576989a7B71869e945eabB3E043eFf76',

    SwapAddress: '0x5888C998a3a98db1f966a670827894641dde0Bc0',
    BridgeSolanaAddress: '0x0b382531f186c665C23A5560e9844505d2286595',

    RewardAddress: '',
  },
  POLYGONTEST: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0xc0AE17Eb994fa828540FFa53776B3830233A1B02',
    PerpetualTrade: '0x9eC57573aFfDA29D78787fc98d0511e1A39eE75e',
    PrivatePool: '0x50b9D4a006254D330AaA0f264D2739A3f3a7D8E1',
    Exchanger: '0xd72a11Afb7ffA3A88e1bCf600a76Ad8eBda6439a',
    perpetualProxy: '0xDeb93041bdb9893e9aE1a487e62aaa2002B4629d',

    SwapAddress: '0xbd52094a882270DF0f34a0b2E5c914f2BE106bD1',
    BridgeSolanaAddress: '0x0fFe05853658137544c8C7948bA21B7EE2274B2b',

    RewardAddress: '',
  },
  CORE: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    NFTAddress: '0xf86ef3d215377cb4ea13ddd1ed5c9486a822cdea',
    NFTAddressV2: '0x81D9546c2a9F5286a299eB867Ef30D00AA697bA2',
    WhitelistNFTAddress: '0x81D9546c2a9F5286a299eB867Ef30D00AA697bA2',
    NFTBuyAddress: '0x81D9546c2a9F5286a299eB867Ef30D00AA697bA2',

    PerpetualAddress: '0x8aa4e0a91cD19C7E88f3e9EB406957020F109Fd2',
    PerpetualTrade: '0x6c35D84b9d9c08DA18dfb5AbEB01b358D6F58E63',
    PrivatePool: '0x60101E4388D1c2B389d78daC29d37Ee2DAc88e07',
    Exchanger: '0x122373646CA17C92EB52f3784c7ab1B91840c887',
    perpetualProxy: '0x4c1ebD85353bAB2611840e17f968306dBbDD1F42',

    SwapAddress: '0x5A91083a00b8329b0d62F4fec1E1641A3193B650',
    BridgeSolanaAddress: '0xB20120C212F0Ec646858D2D27F1143de7f10AD9B',

    RewardAddress: '0xe3f9A7Ea4974fFf9b74E9769f5fa525220EA4c9D',
  },

  CORETEST: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0x6d9dAdcb0d6e5d10FB082b55C033e7Df6dFceE94',
    PerpetualTrade: '0xd72a11Afb7ffA3A88e1bCf600a76Ad8eBda6439a',
    PrivatePool: '0x93614e83C2d2874616B60B550215637eE84c5eAB',
    Exchanger: '0xc0AE17Eb994fa828540FFa53776B3830233A1B02',
    perpetualProxy: '0xcE09438b48Bd776280863a00a6eF012296a56817',

    SwapAddress: '0x2e8a080C6d691996E1abC7f6C323b210Ebd07D68',
    BridgeSolanaAddress: '0x5984d58abF8150345163f49F4C3999F454123552',

    RewardAddress: '',
  },

  MERLIN: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0xc0AE17Eb994fa828540FFa53776B3830233A1B02',
    PerpetualTrade: '0x2bE26b2d750c9C72327bA0828982EB9571F2591c',
    PrivatePool: '0xcE09438b48Bd776280863a00a6eF012296a56817',
    Exchanger: '0xd72a11Afb7ffA3A88e1bCf600a76Ad8eBda6439a',
    perpetualProxy: '0xFf8Dfd3CC62dd15E24932ccF43364F89246c9A8F',

    SwapAddress: '0x5fC939433833b39a7eF53A7f78Ac06583616c00F',
    BridgeSolanaAddress: '0xfb5680D371158EfD557C876a9234f5D6eaF17816',

    RewardAddress: '',
  },
  MERLINTEST: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0xc0AE17Eb994fa828540FFa53776B3830233A1B02',
    PerpetualTrade: '0x2bE26b2d750c9C72327bA0828982EB9571F2591c',
    PrivatePool: '0xcE09438b48Bd776280863a00a6eF012296a56817',
    Exchanger: '0xd72a11Afb7ffA3A88e1bCf600a76Ad8eBda6439a',
    perpetualProxy: '0xFf8Dfd3CC62dd15E24932ccF43364F89246c9A8F',

    SwapAddress: '0x5fC939433833b39a7eF53A7f78Ac06583616c00F',
    BridgeSolanaAddress: '0xfb5680D371158EfD557C876a9234f5D6eaF17816',

    RewardAddress: '',
  },
  ZKFAIR: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0x06ACC04578E08C76Cb00F4D0a3D22C6A8c337299',
    PerpetualTrade: '0xA48a68802A120D9478BA9b1A781C9707e84724c0',
    PrivatePool: '0x37D8A51d9621041d6b9276ea8a835553b31698c7',
    Exchanger: '0xecC1104f3BA8209F48C70b6420E997bD5EE7530f',
    perpetualProxy: '0x7e12D36eC70A19AE1A50F60a3e725ef0B51dfd97',

    SwapAddress: '0x93614e83C2d2874616B60B550215637eE84c5eAB',
    BridgeSolanaAddress: '0xfb5680D371158EfD557C876a9234f5D6eaF17816',

    RewardAddress: '',
  },
  ZKFAIRTEST: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0xc0AE17Eb994fa828540FFa53776B3830233A1B02',
    PerpetualTrade: '0x9eC57573aFfDA29D78787fc98d0511e1A39eE75e',
    PrivatePool: '0x50b9D4a006254D330AaA0f264D2739A3f3a7D8E1',
    Exchanger: '0xd72a11Afb7ffA3A88e1bCf600a76Ad8eBda6439a',
    perpetualProxy: '0xDeb93041bdb9893e9aE1a487e62aaa2002B4629d',

    SwapAddress: '0x93614e83C2d2874616B60B550215637eE84c5eAB',
    BridgeSolanaAddress: '0xfb5680D371158EfD557C876a9234f5D6eaF17816',

    RewardAddress: '',
  },
  MANTA: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0x06ACC04578E08C76Cb00F4D0a3D22C6A8c337299',
    PerpetualTrade: '0xA48a68802A120D9478BA9b1A781C9707e84724c0',
    PrivatePool: '0x37D8A51d9621041d6b9276ea8a835553b31698c7',
    Exchanger: '0xecC1104f3BA8209F48C70b6420E997bD5EE7530f',
    perpetualProxy: '0x7B5b04942E45666f981eC6C79a2f8c4B4e502307',

    SwapAddress: '0x8D17b774057791D94Cee17D13b1E20691800f7E8',
    BridgeSolanaAddress: '0xfb5680D371158EfD557C876a9234f5D6eaF17816',

    RewardAddress: '',
  },
  MANTATEST: {
    BTCPerpetualUnderlyingAsset: '0xDf42A20a4a4695f6Da6fCa0EDBD420de378a378E',
    ETHPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    SOLPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    BNBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    DOGEPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    ARBPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    OPPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    WLDPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',
    LINKPerpetualUnderlyingAsset: '0xE7904A60961254E2185025558c37B7B03529373E',

    PerpetualAddress: '0x6d9dAdcb0d6e5d10FB082b55C033e7Df6dFceE94',
    PerpetualTrade: '0xd72a11Afb7ffA3A88e1bCf600a76Ad8eBda6439a',
    PrivatePool: '0x2bE26b2d750c9C72327bA0828982EB9571F2591c',
    Exchanger: '0xc0AE17Eb994fa828540FFa53776B3830233A1B02',
    perpetualProxy: '0xDeb93041bdb9893e9aE1a487e62aaa2002B4629d',

    SwapAddress: '0x8D17b774057791D94Cee17D13b1E20691800f7E8',
    BridgeSolanaAddress: '0xfb5680D371158EfD557C876a9234f5D6eaF17816',

    RewardAddress: '',
  },
};
export const NetworkFilter = (chinId) => {
  switch (Number(chinId)) {
    case mainnet.id:
      return 'ETH';
    case bsc.id:
      return 'BSC';
    case bscTestnet.id:
      return 'BSCTEST';
    case opBNB.id:
      return 'OPBNB';
    case opBNBTestnet.id:
      return 'OPBNBTEST';
    case arbitrum.id:
      return 'ARB';
    case arbitrumGoerli.id:
      return 'ARBTEST';
    case arbitrumSepolia.id:
      return 'ARBTEST';
    case eos.id:
      return 'EOS';
    case eosTestnet.id:
      return 'EOSTEST';
    case polygon.id:
      return 'POLYGON';
    case polygonMumbai.id:
      return 'POLYGONTEST';
    case coreDao.id:
      return 'CORE';
    case coreDaoTestnet.id:
      return 'CORETEST';
    case merlin.id:
      return 'MERLIN';
    case merlinTestnet.id:
      return 'MERLINTEST';
    case zkFair.id:
      return 'ZKFAIR';
    case zkFairTestnet.id:
      return 'ZKFAIRTEST';
    case manta.id:
      return 'MANTA';
    case mantaSepolia.id:
      return 'MANTATEST';
    default:
      return '';
  }
};
export const ChainTypeFilter = (chinId) => {
  return NetworkFilter(chinId).replaceAll('TEST', '');
};
