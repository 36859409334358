import BigNumber from 'bignumber.js';
import { debounce } from 'lodash';
import { useI18n } from 'vue-i18n';

export const fixDEAdd = (num, precision, autoFix = true) => {
  if (`${num}` === '0') {
    if (!parseFloat(precision) || !autoFix) return 0;
    return '0.'.padEnd(precision + 2, '0');
  }
  if (!num) return '--';
  const number = parseFloat(num);
  const strN = num.toString();
  const flag = number < 0;
  let result = strN;

  if (strN.toLowerCase().indexOf('e') > -1) {
    const n = strN.match(/(\d+?)(?:\.(\d*))?e([+-])(\d+)/);
    const nl = n[1];
    const nr = n[2];
    const type = n[3];
    const floatN = n[4];
    let params = '';
    let pr = nr ? nr.substr(floatN) : '';

    if (pr) pr = `.${pr}`;
    if (type !== '-') {
      for (let i = 0; i < floatN; i += 1) {
        const p = nr[i] || '0';
        params += p;
      }
      result = nl + params + pr;
    } else {
      let strl = '0';
      for (let i = 0; i < floatN; i += 1) {
        const p = nl[nl.length - i - 1] || '0';
        params = p + params;
      }
      if (nl.length > floatN) strl = nl.substr(0, nl.length - floatN);
      result = `${strl}.${params}${nr}`;
    }
  }

  if (precision && autoFix) {
    let pal = `${result.split('.')[0]}.`;
    const par = result.split('.')[1] || '';

    for (let i = 0; i < precision; i += 1) {
      pal += par[i] || '0';
    }
    result = pal;
  }

  if (result.length > 14) {
    const arry = result.split('.');
    if (arry[0].length > 14) {
      result = `${arry[0].slice(0, 14)}+`;
    } else {
      result = result.slice(0, 13);
      if (result.indexOf('.') === 12) {
        result = result.slice(0, 12);
      }
    }
  }

  return `${flag ? '-' : ''}${result}`;
};

export const fixD = (num, precision) => {
  precision = precision > -1 ? precision : 0;
  if (`${num}` === '0') {
    if (!parseFloat(precision)) {
      return 0;
    }
    return '0.'.padEnd(precision + 2, '0');
  }
  if (!num) {
    return '--';
  }
  let flag = false;
  if (parseFloat(num) < 0) {
    flag = true;
  }

  const newnum = `${Math.abs(parseFloat(num))}`;
  if (newnum === 'NaN') {
    return '--';
  }
  let fixNum = newnum;
  if (newnum.toLowerCase().indexOf('e') > -1) {
    if (newnum.toLowerCase().indexOf('+') > -1)
      return fixDEAdd(newnum, precision);
    const a = newnum.toLowerCase().split('e');
    let b = a[0];
    const c = Math.abs(parseFloat(a[1]));
    let d = '';
    let h = b.length;
    let i;
    if (a[0].split('.')[1]) {
      b = a[0].split('.')[0] + a[0].split('.')[1];
      h = a[0].split('.')[0].length;
    }
    for (i = 0; i < c - h; i += 1) {
      d += '0';
    }
    fixNum = `0.${d}${b}`;
  }
  if (`${precision}` !== '0' && !precision) {
    return (flag ? '-' : '') + fixNum;
  }
  if (`${parseFloat(num)}` === 'NaN') {
    return (flag ? '-' : '') + fixNum;
  }
  const fNum = fixNum.split('.');
  if (precision === 0) {
    fixNum = parseInt(fixNum, 10);
  } else if (precision > 0 && fNum[1]) {
    if (fNum[1].length > precision) {
      if (fNum[1].indexOf('999999999') > -1) {
        const s = parseFloat(fixNum).toFixed(precision + 1);
        fixNum = s.slice(0, s.length - 1);
      } else {
        fixNum = `${fNum[0]}.${fNum[1].slice(0, precision)}`;
      }
    } else {
      fixNum = parseFloat(fixNum).toFixed(precision);
    }
  } else {
    fixNum = parseFloat(fixNum).toFixed(precision);
  }
  if (fixNum.length >= 14 && fixNum.indexOf('.') > -1) {
    const arry = fixNum.split('.');
    if (arry[0].length > 14) {
      fixNum = `${arry[0].slice(0, 14)}+`;
    } else {
      fixNum = fixNum.slice(0, 19);
      if (fixNum.indexOf('.') === 18) {
        fixNum = fixNum.slice(0, 18);
      }
    }
  }
  return (flag ? '-' : '') + fixNum;
};
export const addCommom = (value: any, length = undefined) => {
  if (isNaN(value) || !value) return value;
  value = String(value);
  if (!isNaN(length)) {
    if (length === 0) {
      value = value.split('.')[0];
    }
    return value.replace(/(\d+)(\.\d+)?/, (a, b, c) => {
      return (
        b.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') +
        (c ? c.slice(0, length + 1) : '')
      );
    });
  } else {
    return value.replace(/^\d+/, (a) => {
      return a.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
    });
  }
};

export const toBigNumber = (num: any) => {
  if (num) {
    return new BigNumber(num);
  }
  return new BigNumber(0);
};

export const formatNumber = (
  num: number | string,
  precision = 2,
  roundingMode: BigNumber.RoundingMode = 1
) => {
  const { n } = useI18n({
    useScope: 'global',
  });

  return n(Number(toBigNumber(num).toFixed(precision, roundingMode)), {
    style: 'decimal',
    maximumFractionDigits: precision,
    minimumFractionDigits: 0,
  });
};

const errorMap = [
  {
    msg: 'User rejected the request',
    msgKey: 'Message.cancel',
  },
  {
    msg: 'User Rejected',
    msgKey: 'Message.cancel',
  },
  {
    msg: 'liquidity is not enough',
    msgKey: 'Trade.liquidityIsNotEnough',
  },
  {
    msg: 'lp is not enough',
    msgKey: 'Trade.liquidityIsNotEnough',
  },
  {
    msg: 'lp balance is not enough',
    msgKey: 'Trade.liquidityIsNotEnough',
  },
  {
    msg: 'account should be detonate',
    msgKey: 'Trade.detonate',
  },
  {
    msg: 'your address not in white list',
    msgKey: 'NFT.noWhitelist',
  },
  {
    msg: 'you not in whitelist',
    msgKey: 'NFT.noWhitelist',
  },
  {
    msg: 'intrinsic gas too low',
    msgKey: 'Message.gasTooLow',
  },
  {
    msg: 'this address have minted',
    msgKey: 'NFT.addressMinted',
  },
  {
    msg: 'EXPIRED',
    msgKey: 'Message.orderExpired',
  },
  {
    msg: 'caller is not perpetual keeper',
    msgKey: 'Message.notPerpetualKeeper',
  },
  {
    msg: 'too small',
    msgKey: 'Message.tooSmall',
  },
  {
    msg: 'close amount exceed',
    msgKey: 'Message.closeAmountExceed',
  },
  {
    msg: 'execution reverted: exceed',
    msgKey: 'Message.exceed',
  },
  {
    msg: 'open trigger price is zero',
    msgKey: 'Message.openIsZero',
  },
  {
    msg: 'close trigger price is zero',
    msgKey: 'Message.closeIsZero',
  },
  {
    msg: 'price signature is not correct',
    msgKey: 'Message.priceSignatureNotCorrect',
  },
  {
    msg: 'amount is zero',
    msgKey: 'Message.amountIsZero',
  },
  {
    msg: 'unSupport',
    msgKey: 'Message.unSupport',
  },
  {
    msg: 'Do not trade status',
    msgKey: 'Message.notTradeState',
  },
  {
    msg: 'wrong type',
    msgKey: 'Message.wrongType',
  },
  {
    msg: 'Do not remove pair',
    msgKey: 'Message.notRemovePair',
  },
  {
    msg: 'caller is not order book keeper',
    msgKey: 'Message.notOrderKeeper',
  },
  {
    msg: 'reach deals top line',
    msgKey: 'Message.dealsTopLine',
  },
  {
    msg: 'limit close amount exceed',
    msgKey: 'Message.limitCloseAmountExceed',
  },
  {
    msg: 'position size is zero',
    msgKey: 'Message.positionIsZero',
  },
  {
    msg: 'Wrong state in canceling',
    msgKey: 'Message.wrongStateInCanceling',
  },
  {
    msg: 'Not holder',
    msgKey: 'Message.notHolder',
  },
  {
    msg: 'cancel amount is great than freeze',
    msgKey: 'Message.cancelAmountGreatThanFreeze',
  },
  {
    msg: 'Exceed size',
    msgKey: 'Message.exceedSize',
  },
  {
    msg: 'Invalid direction',
    msgKey: 'Message.invalidDirection',
  },
  {
    msg: 'wrong deposit flag',
    msgKey: 'Message.wrongDepositFlag',
  },
  {
    msg: 'wallet is escrow account',
    msgKey: 'Message.escrowAccount',
  },
  {
    msg: 'caller is unAllowed maker',
    msgKey: 'Message.unAllowedMaker',
  },
  {
    msg: 'insufficient allowance',
    msgKey: 'Message.insufficientAllowance',
  },
  {
    msg: 'do not allowed',
    msgKey: 'Message.notAllowed',
  },
  {
    msg: 'wrong withdraw flag',
    msgKey: 'Message.wrongWithdrawFlag',
  },
  {
    msg: 'locked up',
    msgKey: 'Message.lockedUp',
  },
  {
    msg: 'lower the amount',
    msgKey: 'Message.lowerAmount',
  },
  { msg: 'unAllow close', msgKey: 'Message.unAllowClose' },
  { msg: 'Not maker', msgKey: 'Message.notMaker' },
  { msg: 'Liquidity status wrong', msgKey: 'Message.LiquidityStatusWrong' },
  { msg: 'Exceed available', msgKey: 'Message.ExceedAvailable' },
  { msg: 'pool status is refuse', msgKey: 'Message.poolStatusIsRefuse' },
  { msg: 'need deposit', msgKey: 'Message.needDeposit' },
  { msg: 'user is not exists', msgKey: 'Message.userNotExists' },
  {
    msg: 'escrow account should be detonate',
    msgKey: 'Message.escrowAccountShouldDetonate',
  },
  {
    msg: 'position amount is insufficient',
    msgKey: 'Message.positionAmountInsufficient',
  },
  {
    msg: 'caller is not agent keeper',
    msgKey: 'Message.callerIsNotAgentKeeper',
  },
  { msg: 'Invalid state', msgKey: 'Message.InvalidState' },
  { msg: 'PNL error', msgKey: 'Message.PNLError' },
  {
    msg: 'amount is great then freeze',
    msgKey: 'Message.amountIsGreatThenFreeze',
  },
  {
    msg: 'caller is not station keeper',
    msgKey: 'Message.callerIsNotStationKeeper',
  },
  {
    msg: 'caller is not underlying keeper',
    msgKey: 'Message.callerIsNotUnderlyingKeeper',
  },
  {
    msg: 'Settle address is not token',
    msgKey: 'Message.SettleAddressIsNotToken',
  },
  { msg: 'Invalid primary address', msgKey: 'Message.InvalidPrimaryAddress' },
  { msg: 'name is empty', msgKey: 'Message.nameIsEmpty' },
  {
    msg: 'less then lowest trading fee rate',
    msgKey: 'Message.lessThenLowestTradingFeeRate',
  },
  { msg: 'zero', msgKey: 'Message.zero' },
  { msg: 'price was expired', msgKey: 'Message.priceWasExpired' },
  {
    msg: 'Transaction was not mined within 50 blocks',
    msgKey: 'Message.transactionWaiting',
  },
  {
    msg: 'User rejected the request',
    msgKey: 'Message.cancel',
  },
  {
    msg: 'Not yet open',
    msgKey: 'Message.nftNotOpen',
  },
  {
    msg: 'The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account.',
    msgKey: 'Message.gasExceedBalance',
  },
  {
    msg: 'notLogin',
    msgKey: 'Message.notLogin',
  },
  {
    msg: 'addressEmpty',
    msgKey: 'Message.addressEmpty',
  },
  {
    msg: 'receiveAddressError',
    msgKey: 'swap.receiveAddressError',
  },
];

interface IError {
  message: string;
  code?: number;
  receipt?: {
    transactionHash?: string;
  };
  reason?: string;
  shortMessage?: string;
  details?: string;
}
export function parsingException(e: IError) {
  console.log(e);
  console.trace(e.reason, e.shortMessage, e.details);
  if (e.code === 4001) {
    return 'Message.cancel';
  }
  if (e.code === 1) {
    return 'NFT.claimError';
  }
  const error = errorMap.find((item) =>
    (e.reason || e.shortMessage?.includes('unknown RPC')
      ? e.details
      : e.shortMessage || e.message
    )?.includes(item.msg)
  );
  return error?.msgKey || 'Message.contractExecuteError';
}

export function asyncDebounce<F extends (...args: any[]) => Promise<any>>(
  func: F,
  wait?: number,
  maxWait = 100
) {
  const resolveSet = new Set<(p: any) => void>();
  const rejectSet = new Set<(p: any) => void>();

  const debounced = debounce(
    (args: Parameters<F>) => {
      func(...args)
        .then((...res) => {
          resolveSet.forEach((resolve) => resolve(...res));
          resolveSet.clear();
        })
        .catch((...res) => {
          rejectSet.forEach((reject) => reject(...res));
          rejectSet.clear();
        });
    },
    wait,
    {
      maxWait,
    }
  );

  return (...args: Parameters<F>): ReturnType<F> =>
    new Promise((resolve, reject) => {
      resolveSet.add(resolve);
      rejectSet.add(reject);
      debounced(args);
    }) as ReturnType<F>;
}
