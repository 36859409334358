<script setup lang="ts">
import { ElNotification } from "element-plus";
import { storeToRefs } from "pinia";
import {
  h,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useI18n } from "vue-i18n";
import MessageLoading from "@/assets/images/common/message_loading.svg";
import MessageError from "@/assets/images/common/message_error.svg";
import MessageSuccess from "@/assets/images/common/message_success.svg";
import Warning from '@/assets/images/common/Warning.svg';
import Progress from "@/components/common/progress/index.vue";
import { useCommonStore } from "@/store/index";
const commonStore = useCommonStore();
const { messageTips, messageText, messageType } = storeToRefs(commonStore);
const { t } = useI18n();
const notificationRef = ref();
watch(
  () => messageTips.value,
  (value) => {
    if (!value) {
      notificationRef.value.close();
    }
  },
  { deep: true }
);
const initLoading = () => {
  notificationRef.value && notificationRef.value.close();
  notificationRef.value = ElNotification({
    message: h(
      "div",
      {
        class: "loading-notify",
      },
      [
        h("img", { class: "loading-notify-icon", src: MessageLoading }),
        h("span", { class: "loading-notify-message" }, t("Message.Loading")),
      ]
    ),
    type: "info",
    duration: 0,
    showClose: false,
    customClass: "loading-notification",
  });
};
const initError = () => {
  notificationRef.value && notificationRef.value.close();
  notificationRef.value = ElNotification({
    message: h(
      "div",
      {
        class: "error-notify",
      },
      [
        [
          h("img", { class: "error-notify-icon", src: MessageError }),
          h("span", { class: "error-notify-message" }, messageText.value),
          h(Progress, {
            style: { marginTop: "6px" },
            duration: 4000,
          }),
        ],
      ]
    ),
    type: "info",
    duration: 4000,
    showClose: true,
    customClass: "error-notification",
  });
  commonStore.setMessageType("");
};
const initWarn = () => {
  notificationRef.value && notificationRef.value.close();
  notificationRef.value = ElNotification({
    message: h(
        "div",
        {
          class: "warn-notify",
        },
        [
          [
            h("img", { class: "warn-notify-icon", src: Warning }),
            h("div", { class: "warn-notify-message" }, messageText.value),
            h(Progress, {
              style: { marginTop: "6px" },
              duration: 4000,
            }),
          ],
        ]
    ),
    type: "info",
    duration: 4000,
    showClose: true,
    customClass: "warn-notification",
  });
  commonStore.setMessageType("");
};
const initSuccess = () => {
  notificationRef.value && notificationRef.value.close();
  notificationRef.value = ElNotification({
    message: h(
      "div",
      {
        class: "success-notify",
      },
      [
        h("img", { class: "success-notify-icon", src: MessageSuccess }),
        h("span", { class: "success-notify-message" }, messageText.value),
        h(Progress, {
          style: { marginTop: "6px" },
          duration: 4000,
        }),
      ]
    ),
    type: "info",
    duration: 4000,
    showClose: true,
    customClass: "success-notification",
  });
  commonStore.setMessageType("");
};
watchEffect(() => {
  if (messageTips.value) {
    if (messageType.value == "loading") {
      initLoading();
    }
    if (messageType.value == "success") {
      initSuccess();
    }
    if (messageType.value == "error") {
      initError();
    }
    if (messageType.value == "warn") {
      initWarn();
    }
  }
});
</script>
<template>
  <div
    class="message_mask"
    v-if="messageTips && messageType == 'loading'"
  ></div>
</template>

<style lang="less" scoped>
.message_mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
